<template>
  <div class="tabbar">
    <div class="item column-center" @click="btnChangeTabbar('/')">
      <img v-if="routePath === '/'" src="@/assets/images/tabbar/Inicio.png" />
      <img v-else src="@/assets/images/tabbar/Inicio-h.png" />
      <span :style="routePath === '/' ? 'color: #e5b53b;' : 'color: #fff;'"
        >Inicio</span
      >
    </div>
    <div class="item column-center" @click="btnChangeTabbar('/home/event')">
      <img
        v-if="routePath === '/home/event'"
        src="@/assets/images/tabbar/Promocao.png"
      />
      <img v-else src="@/assets/images/tabbar/Promocao-h.png" />
      <span
        :style="
          routePath === '/home/event' ? 'color: #e5b53b;' : 'color: #fff;'
        "
        >{{ $store.state.isLogin ? 'Depositto' : 'Promoção' }}</span
      >
    </div>
    <div
      v-if="$store.state.isLogin"
      class="item column-center"
      @click="btnChangeTabbar('/home/promote')"
    >
      <img
        v-if="routePath === '/home/promote'"
        src="@/assets/images/tabbar/Agenteh-h.png"
      />
      <img v-else src="@/assets/images/tabbar/Agenteh-h.png" />
      <span
        :style="
          routePath === '/home/promote' ? 'color: #e5b53b;' : 'color: #fff;'
        "
        >Agente</span
      >
    </div>
    <div
      v-else
      class="item column-center"
      @click="btnChangeTabbar('/home/promote')"
    >
      <img src="@/assets/images/tabbar/Agenteh-h.png" />
      <span
        :style="
          routePath === '/home/promote' ? 'color: #e5b53b;' : 'color: #fff;'
        "
        >Login</span
      >
    </div>
    <div class="item column-center" @click="btnChangeTabbar('/home/notice')">
      <img src="@/assets/images/tabbar/Suporteh.png" />
      <span
        :style="
          routePath === '/home/notice' ? 'color: #e5b53b;' : 'color: #fff;'
        "
        >Suporte</span
      >
    </div>
    <div class="item column-center" @click="btnChangeTabbar('/home/profile')">
      <img src="@/assets/images/tabbar/Perfil-h.png" />
      <span
        :style="
          routePath === '/home/profile' ? 'color: #e5b53b;' : 'color: #fff;'
        "
        >Perfil</span
      >
    </div>
  </div>
</template>

<script>
import { EventBus } from '../utils/eventBus'
export default {
  data() {
    return {
      active: 0,
      routePath: window.location.pathname,
      arr: [{ name: '', url: '' }],
      needLoginRouterArr: ['/home/promote', '/home/profile', '/home/notice'],
    }
  },
  methods: {
    btnChangeTabbar(path) {
      if (this.needLoginRouterArr.find((i) => i === path) && !this.$store.state.isLogin) {
        // this.$emit('show:register')
          EventBus.$emit('show-login')
      } else if(path=="/" && !this.$store.state.isLogin){
        EventBus.$emit('show-login')
      } else {
        this.routePath = path
        this.$router.push({ path })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.tabbar {
  position: fixed;
  bottom: 0;
  display: flex;
  background-color: #242424;
  width: 435px;
  height: 0.7rem;
  margin-top: 100px;

  .item {
    flex: 1;
    color: #242424;
    img {
      width: 0.35rem;
      height: 0.35rem;
    }
  }
}
</style>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .tabbar {
    width: 100vw;
  }
}
</style>
