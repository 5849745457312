<template>
  <div class="deposit">
    <header>
      <van-icon
        name="arrow-left"
        size="0.3rem"
        class="left_btn"
        @click="$router.back()"
        style="cursor: pointer;"
      />
      <h2>Depósito</h2>
      <span style="cursor: pointer;" @click="showSummary = true"
        >Registro de recarga</span
      >
    </header>
    <main>
      <div class="row">
        <span class="item">
          <img src="@/assets/images/home/phone.png" />
          <van-badge content="0.5%">
            <span style="color: #feb705; font-weight: bold;"
              >Depósito on-line</span
            >
          </van-badge>
        </span>
      </div>
      <div class="row02">
        <van-badge content="0.5%">
          <span class="item selected">
            <img
              class="small-button"
              src="@/assets/images/other/icon_normal_pix.png"
            />
            <span>PIX (Quente)</span>
            <!-- <span class="checked">✔</span> -->
          </span>
        </van-badge>
      </div>
    </main>
    <div class="ipt_box">
      <div class="between">
        <span style="font-size: larger;">Valor do Depósito</span>
        <span style="color: var(--yellow);"
          >Detalhes adicionais do evento de bónus</span
        >
      </div>
      <div class="boxs">
        <template v-for="(item, index) in depositArr">
          <van-badge v-if="item.rewardSize != 0" :content="item.rewardSize">
            <div
              class="box center"
              :class="{ selected: index === active }"
              @click="
                () => {
                  active = index
                  amount = item.amount
                }
              "
            >
              {{ item.amount }}
            </div>
          </van-badge>
          <div
            v-else
            class="box center"
            :class="{ selected: index === active }"
            @click="
              () => {
                active = index
                amount = item.amount
              }
            "
          >
            {{ item.amount }}
          </div>
        </template>
      </div>
      <a-input
        v-model="amount"
        placeholder="Mínimo 10, Máximo 100000"
        allowClear
      >
        <template #prefix>
          <span style="font-weight: bolder; font-size: larger;">R$</span>
        </template>
      </a-input>
<!--      <div>-->
<!--        <div>Se deve participar da atividade de recarga</div>-->
<!--          <input type="radio" id="checkYes" value="yes" v-model="checkedNames">-->
<!--          <label for="checkYes">Sim</label>-->
<!--          &nbsp;&nbsp;&nbsp;&nbsp;-->
<!--          <input  type="radio" id="checkNo" value="no" v-model="checkedNames">-->
<!--          <label for="checkNo">Não</label>-->
<!--      </div>-->
      <button
        @click="deposit()"
        :style="{ 'background-color': isLoading ? '#777' : 'orange' }"
        :disabled="isLoading"
      >
        Recarregue Agora
      </button>
    </div>
    <van-action-sheet v-model="showSummary">
      <Summary
        v-if="showSummary"
        @close-summary="showSummary = false"
      ></Summary>
    </van-action-sheet>
<!--      <div style="">-->
<!--          <iframe v-if="showIframe" style="width: 100%; height: 100vh; border: none;" :src="url"></iframe>-->
<!--      </div>-->
      <van-popup v-model="showIframe" closeable class="zhifu_tc">
          <iframe style="width: 100%; height: 100vh; border: none;" :src="url"></iframe>
      </van-popup>
  </div>
</template>

<script>
import { depositApi, getDepositInfoApi,getQualificationsJudge,saveActivityInfo } from '../../api'
import FacebookService  from '@/utils/FacebookService.js'
import Summary from './Summary.vue'
export default {
  components: { Summary },
  data() {
    return {
      arr: [
        {
          value: 10,
        },
        {
          value: 20,
        },
        {
          value: 30,
        },
        {
          value: 50,
        },
        {
          value: 100,
          have_add: 1,
        },
        {
          value: 500,
          have_add: 5,
        },
        {
          value: 1000,
          have_add: 10,
        },
        {
          value: 5000,
          have_add: 50,
        },
      ],
      active: 0,
      amount: undefined,
      showSummary: false,
      depositArr: [],
      showIpt: false,
      isLoading: false,
      activityBegin:false,
      checkedNames: "",//选择是否参加活动的值
      qualification:false,//是否有参加资格的值
      // showPopup:false,//弹窗是否显示
        url: undefined,
        showIframe:false,
    }
  },
  created() {
    this.getDepositInfo()
    // this.enterActivities()
  },
  mounted() {
    FacebookService.init('480972771120381');  //这里填你的id
  },
  methods: {
    //获取是否有参与历史首次充值的活动资格（true:有  false:无）
    // async enterActivities(){
    //   const { data: qualification } = await getQualificationsJudge()
    //   this.qualification = qualification;
    //   if (this.qualification===true) {
    //     this.showPopup = true
    //   }
    // },
    async getDepositInfo() {
      const { data: data } = await getDepositInfoApi()
      if (data.find((i) => i.rechargeType === 1)) {
        this.showIpt = true
      }
      this.depositArr = data.filter((i) => i.rechargeType === 0)
      this.amount = this.depositArr[0].amount
    },
    async deposit() {
        // if (this.checkedNames==="yes"){
        //   const{msg:msg} = await saveActivityInfo({joinAmount:this.amount})
        //     if (msg=="Promoção de primeiro depósito encerrada"){
        //         this.$toast.fail("Promoção de primeiro depósito encerrada")
        //     }
        // }
        this.isLoading = true
        const { data: url } = await depositApi({ amount: this.amount,checkedNames: this.checkedNames })
        // location.href = url
        this.url = url
        this.showIframe = true
        console.log(url)
        //your do是你要传的事件类型，params是要传的参数没有可以填null
        // FacebookService.trackEvent('Purchase', {currency: "BNL", value: this.amount});
        this.isLoading = false

    },
  },
}
</script>

<style lang="scss" scoped>
.checked {
  //   background: var(--yellow);
  color: black;
  border-color: transparent var(--yellow) transparent transparent;
  border-width: 10px 0px px 0px;
  border-style: solid;
}

.deposit {
  padding-top: 10px;
  height: 100vh;
  background: var(--dark-bg);
  header {
    position: relative;
    text-align: center;
    .left_btn {
      position: absolute;
      left: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
    h2 {
      color: #e3e3e3;
    }
    span {
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 2px;
      color: #feb705;
    }
  }

  main {
    padding: 0 0.2061rem;
    padding-top: 0.2rem;
    .row {
      padding-bottom: 8px;
      border-bottom: 1px solid var(--boder-color);
      .item {
        border-bottom: 3px solid #feb705;
        padding-bottom: 15px;
        img {
          width: 0.35rem;
          height: 0.35rem;
          margin-left: -5px;
        }
      }
    }
    .row02 {
      padding: 0.3rem 0;
      border-bottom: 1px solid var(--boder-color);
      .item {
        padding: 0.15rem;
        border: 1px solid var(--yellow);
        border-radius: 5px;
        img {
          margin-left: -5px;
          margin-right: 5px;
        }
      }
    }
  }

  .ipt_box {
    padding: 0.2061rem;

    .boxs {
      padding: 0.2rem 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 15px;

      .box {
        flex: 1;
        border: 1px solid var(--boder-color);
        border-radius: 5px;
        height: 0.4848rem;
      }
    }
  }

  button {
    width: 100%;
    padding: 0.1rem 0;
    margin-top: 0.3rem;
    color: black;
    font-size: larger;
  }
}

::v-deep(.ant-input) {
  padding: 0.3rem 0;
}

::v-deep(.ant-input-prefix) {
  left: 5px !important;
}

::v-deep(.van-popup) {
  max-width: 435px;
  top: 60px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--dark-bg);
}

::v-deep(.van-overlay) {
  max-width: 435px;
  left: 50%;
  transform: translateX(-50%);
}

::v-deep(.van-action-sheet) {
  max-height: none;
}
.zhifu_tc{
    position: fixed;
    top: 5%;
    background: white;
    left: 50%;
    transform: translateX(-50%);
    width: 340px;
    height: 90%;
    border-radius: 10px;
}
@media screen and (min-width: 768px) {
    .zhifu_tc{
        width: 390px;
    }
}
</style>
