var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"app"},[(_vm.showSplash)?_c('div',{staticClass:"splash-screen"},[_vm._m(0)]):_c('div',{staticClass:"container"},[_c('router-view',{staticClass:"router-box",class:{ haveTabbar: _vm.tabbarArrPage.find((i) => i === _vm.$route.path) }}),_c('Tabbar',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabbarArrPage.find((i) => i === _vm.$route.path)),expression:"tabbarArrPage.find((i) => i === $route.path)"}],on:{"show:register":(val) => (_vm.showRegister = true)}}),_c('van-dialog',{attrs:{"showConfirmButton":false},model:{value:(_vm.showRegister),callback:function ($$v) {_vm.showRegister=$$v},expression:"showRegister"}},[_c('Register',{on:{"close:register":(val) => (_vm.showRegister = false),"open:login":() => {
              _vm.showRegister = false
              _vm.showLogin = true
            }}})],1),_c('van-dialog',{attrs:{"showConfirmButton":false},model:{value:(_vm.showLogin),callback:function ($$v) {_vm.showLogin=$$v},expression:"showLogin"}},[_c('Login',{on:{"close:login":() => (_vm.showLogin = false),"show:register":() => {
              _vm.showLogin = false
              _vm.showRegister = true
            }}})],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup"},[_c('img',{staticClass:"logo",staticStyle:{"margin-top":"-200px"},attrs:{"src":require("@/assets/BigWnr.gif"),"alt":"Rumax Logo"}})])
}]

export { render, staticRenderFns }