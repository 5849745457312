var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"deposit"},[_c('header',[_c('van-icon',{staticClass:"left_btn",staticStyle:{"cursor":"pointer"},attrs:{"name":"arrow-left","size":"0.3rem"},on:{"click":function($event){return _vm.$router.back()}}}),_c('h2',[_vm._v("Depósito")]),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.showSummary = true}}},[_vm._v("Registro de recarga")])],1),_c('main',[_c('div',{staticClass:"row"},[_c('span',{staticClass:"item"},[_c('img',{attrs:{"src":require("@/assets/images/home/phone.png")}}),_c('van-badge',{attrs:{"content":"0.5%"}},[_c('span',{staticStyle:{"color":"#feb705","font-weight":"bold"}},[_vm._v("Depósito on-line")])])],1)]),_c('div',{staticClass:"row02"},[_c('van-badge',{attrs:{"content":"0.5%"}},[_c('span',{staticClass:"item selected"},[_c('img',{staticClass:"small-button",attrs:{"src":require("@/assets/images/other/icon_normal_pix.png")}}),_c('span',[_vm._v("PIX (Quente)")])])])],1)]),_c('div',{staticClass:"ipt_box"},[_vm._m(0),_c('div',{staticClass:"boxs"},[_vm._l((_vm.depositArr),function(item,index){return [(item.rewardSize != 0)?_c('van-badge',{attrs:{"content":item.rewardSize}},[_c('div',{staticClass:"box center",class:{ selected: index === _vm.active },on:{"click":() => {
                  _vm.active = index
                  _vm.amount = item.amount
                }}},[_vm._v(" "+_vm._s(item.amount)+" ")])]):_c('div',{staticClass:"box center",class:{ selected: index === _vm.active },on:{"click":() => {
                _vm.active = index
                _vm.amount = item.amount
              }}},[_vm._v(" "+_vm._s(item.amount)+" ")])]})],2),_c('a-input',{attrs:{"placeholder":"Mínimo 10, Máximo 100000","allowClear":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_c('span',{staticStyle:{"font-weight":"bolder","font-size":"larger"}},[_vm._v("R$")])]},proxy:true}]),model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('button',{style:({ 'background-color': _vm.isLoading ? '#777' : 'orange' }),attrs:{"disabled":_vm.isLoading},on:{"click":function($event){return _vm.deposit()}}},[_vm._v(" Recarregue Agora ")])],1),_c('van-action-sheet',{model:{value:(_vm.showSummary),callback:function ($$v) {_vm.showSummary=$$v},expression:"showSummary"}},[(_vm.showSummary)?_c('Summary',{on:{"close-summary":function($event){_vm.showSummary = false}}}):_vm._e()],1),_c('van-popup',{staticClass:"zhifu_tc",attrs:{"closeable":""},model:{value:(_vm.showIframe),callback:function ($$v) {_vm.showIframe=$$v},expression:"showIframe"}},[_c('iframe',{staticStyle:{"width":"100%","height":"100vh","border":"none"},attrs:{"src":_vm.url}})])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"between"},[_c('span',{staticStyle:{"font-size":"larger"}},[_vm._v("Valor do Depósito")]),_c('span',{staticStyle:{"color":"var(--yellow)"}},[_vm._v("Detalhes adicionais do evento de bónus")])])
}]

export { render, staticRenderFns }