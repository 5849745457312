<template>
  <div>
    <van-tabs
      v-model="active"
      sticky
      style="height: 100vh; background-color: #303030;"
      :ellipsis="false"
    >
      <div class="back-btn center">
        <van-icon name="arrow-left" size="0.2rem" @click="$router.back()" />
      </div>
      <van-tab title="Saque">
        <saque
          v-if="active === 0"
          @toAddCard="active = 2"
          :bankId="bankId"
        ></saque>
      </van-tab>
      <van-tab title="Registro de Saques">
        <RegistroDeSaques v-if="active === 1"></RegistroDeSaques>
      </van-tab>
      <!-- <van-tab title="Registros de Auditoria">
        <RegistrosDeAuditoria></RegistrosDeAuditoria>
      </van-tab> -->
      <van-tab title="GerenciarConta">
        <gerenciar-conta
          v-if="active === 2"
          @to-first-tab="
            (val) => {
              active = 0
              bankId = val
            }
          "
        ></gerenciar-conta>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import GerenciarConta from './GerenciarConta.vue'
import Saque from './Saque.vue'
import RegistroDeSaques from './RegistroDeSaques.vue'
import RegistrosDeAuditoria from './RegistrosDeAuditoria.vue'
export default {
  components: { Saque, GerenciarConta, RegistroDeSaques, RegistrosDeAuditoria },
  data() {
    return {
      active: 0,
      password: '',
      showKeyboard: false,
      bankId: undefined,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.back-btn {
  position: fixed;
  top: 0;
  width: 0.5rem;
  height: 44px;
  background: #1c1c1c;
}

@media screen and (max-width: 750px) {
  .back-btn {
    height: 35px;
  }
}

::v-deep(.van-button) {
  border-radius: 10%;
}

::v-deep(.van-button__text) {
  color: black;
}

::v-deep(.van-tabs__nav) {
  margin-left: 0.5rem;
}
</style>
