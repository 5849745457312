<template>
  <div class="saque">
    <div class="first">
      <div class="row">
        <div class="left" style="flex: 1;">
          <div class="center" style="justify-content: start;">
            <span style="color: #e3e3e3; font-size: larger;">
              Saldo da Conta
            </span>
<!--            <span style="padding: 0 0.1rem; color: orange;">{{-->
<!--              $store.state.userInfo.balance-->
<!--            }}</span>-->
            <span style="padding: 0 0.1rem; color: orange;">{{ balance |formatNumber}}</span>
            <img
                style="width:25px;cursor:pointer;"
                :src="imageUrl"
                alt="旋转图片"
                @click="rotateImage"
                :style="{ transform: `rotate(${rotation}turn)` }"
            >
          </div>
          <div style="color: #777777;">
            Você precisa apostar <span style="color: #ffa500">R$ {{ bonusLSP |formatNumber }}</span> para sacar
          </div>
        </div>
        <van-button color="#feb705" size="small" style="width: 0.8485rem;"
          >Juros</van-button
        >
      </div>
      <div class="btns">
        <button
          :class="{ selected: btn_active === 0 }"
          @click="btn_active = 0"
          style="overflow: hidden;"
        >
          Retirada normal
        </button>
<!--        <button-->
<!--          :class="{ selected: btn_active === 1 }"-->
<!--          @click="btn_active = 1"-->
<!--          style="overflow: hidden;"-->
<!--        >-->
<!--          Transferir para moeda digital-->
<!--        </button>-->
      </div>
    </div>
    <div class="second">
      <div class="card">
        <div class="row" @click="$emit('toAddCard')">
          <div class="left center">
            <img
              src="@/assets/images/other/card_add.png"
              style="width: 0.3rem; height: 0.3rem;"
            />
            <span v-if="!bankId" style="padding-left: 0.1rem; color: #777777;"
              >Adicionar Conta</span
            >
            <span v-else style="margin-left: 0.1rem;">
              <span v-if="accountDetail.accountType=='CPF'">PIX({{ '****' + accountDetail.cpfAccount.slice(-4) }})</span>
              <span v-if="accountDetail.accountType=='PHONE'">PIX({{ '****' + accountDetail.mobile.slice(-4) }})</span>
              <span v-if="accountDetail.accountType=='EMAIL'">PIX({{ '****' + accountDetail.email.slice(-4) }})</span>
              <span v-if="accountDetail.accountType=='ENPJ'">PIX({{ '****' + accountDetail.email.slice(-4) }})</span>
              <span style="color: #777; padding-left: 0.1rem;">{{accountDetail.accountType}}</span>
            </span>
          </div>
          <van-icon name="arrow" />
        </div>
        <a-input
          v-model="form.amount"
          placeholder="Selecione primeiro a conta para saque"
          style="background-color: #1c1c1c;"
          @click="!bankId ? $emit('toAddCard') : ''"
          type="number"
        >
          <template #prefix style="z-index: 0">
            <span style="color: white;">R$</span>
          </template>
        </a-input>
      </div>
      <div class="password">
        <div class="between" style="padding: 0.1rem 0;">
          <span>Senha de Saque</span>
          <img
            class="small-button"
            @click="hidePassword = !hidePassword"
            :src="
              require(`@/assets/images/other/eye_${
                hidePassword ? 'hide' : 'open'
              }.png`)
            "
          />
        </div>

<!--        <div v-if="!isPC">-->
        <div>
          <van-password-input
              v-model="form.password"
              :focused="showKeyboard"
              :mask="hidePassword"
              @focus="showKeyboard = true"
          />
          <input type="text" class="inputNum" maxlength="6" @click="clickBored" @input="validateInput" v-model="form.password">
<!--          <van-number-keyboard-->
<!--              v-model="form.password"-->
<!--              :show="showKeyboard"-->
<!--              @blur="showKeyboard = false"-->
<!--          />-->
        </div>
<!--        <div v-else>-->
<!--          <van-password-input-->
<!--              v-model="form.password"-->
<!--              :mask="hidePassword"-->
<!--              @focus="startBlinkAnimation"-->
<!--          />-->
<!--          <input type="text" class="inputNum" maxlength="6" @input="validateInput" v-model="form.password">-->
<!--        </div>-->
        <button
          class="confirm_btn"
          :style="{ 'background-color': isLoading ? '#777' : 'orange' }"
          @click="handleSubmit()"
          :disabled="isLoading"
        >
          Confirmar
        </button>
      </div>
    </div>
<!--      <van-overlay :show="showZZ" class="showzz">-->
<!--          <van-loading type="spinner" />-->
<!--      </van-overlay>-->
  </div>
</template>

<script>
import {getUserInfoApi, withdrawApi,getCardDetailApi} from '../../api';
import '@vant/touch-emulator';

export default {
  data() {
    return {
        showZZ:false,
      password: '',
      hidePassword: true,
      showKeyboard: false,
      btn_active: 0,
      form: {
        bankId: undefined,
        amount: undefined,
        password: undefined,
        withdrawType: 0,
        bankType: 'xx',
      },
      isLoading: false,
      imageUrl: require('@/assets/images/my/刷新.png'),
      rotation: 0,
      isRotating: false,
      accountDetail:{},
      balance:'',
      bonusLimit:'',
      bonusProcceedWithdraw:'',
      bonusLSP:'',
      isPC:'',
        timerId:null,
        timerId2:null,
      digitValues: ['', '', '', '', '', ''],
    }
  },
  props: {
    bankId: {
      type: String,
    },
  },
  //   watch: {
  //     bankId(val) {
  //       if (val) {
  //         console.log(val)
  //       }
  //     },
  //   },
  // 在组件的created钩子中使用判断结果
  created() {
    if (this.isAppEndpoint()) {
      this.isPC = false;
      console.log('App端');
    } else {
      this.isPC = true;
      console.log('PC端');
    }
  },
  mounted() {
    this.getUserInfoApi();
    this.getCardDetailApi();
  },
  methods: {
    validateInput() {
      // 只允许输入阿拉伯数字
      this.form.password = this.form.password.replace(/\D/g, '');
    },
    clickBored(){this.showKeyboard = true},
    rotateImage() {
      if (!this.isRotating) {
        this.isRotating = true;
        this.rotation += 2; // 旋转两圈
          this.timerId2 = setTimeout(() => {
          this.isRotating = false;
        }, 2000); // 旋转动画持续时间 2 秒
      }
      getUserInfoApi().then(res=>{
        this.balance = res.data.balance
        console.log(this.balance)
      })
    },
    async handleSubmit() {
        this.showZZ = true
      this.isLoading = true
      this.form.bankId = this.bankId
      const data = await withdrawApi(this.form)
      if (data.code === 200) {
        this.$toast.success(data.msg)
      } else {
        this.$toast.fail(data.msg)
      }
      this.showZZ = false
      this.isLoading = false
    },
    getUserInfoApi(){
      getUserInfoApi().then(res=>{
        this.balance = res.data.balance
        this.bonusLimit = res.data.bonusLimit
        this.bonusLSP = res.data.bonusLSP
        console.log(this.balance)
      })
    },
    getCardDetailApi(){
      console.log("this.bankId="+this.bankId)
      getCardDetailApi(this.bankId).then(res=>{
        this.accountDetail = res.data
      })
    },
    isAppEndpoint() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // 这里的正则表达式可以根据实际情况进行调整
      return /android|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    },
    startBlinkAnimation() {
      // 添加一个类来触发闪烁动画
      const input = document.querySelector('.van-password-input');
      input.classList.add('blink');

      // 3秒后移除闪烁效果，可以根据需要调整时间
        this.timerId = setTimeout(() => {
        input.classList.remove('blink');
      }, 1000);
    },
  },
    beforeDestroy() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
        if (this.timerId2) {
            clearTimeout(this.timerId2);
            this.timerId2 = null;
        }
    }
}
</script>

<style lang="scss" scoped>

/* 定义闪烁动画 */
@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

/* 将闪烁效果应用到输入框 */
.blink {
  animation: blink 1s infinite;  /* 控制闪烁的速度和次数 */
}


.saque {
  .first {
    background: black;
    .row {
      display: flex;
      padding: 0.1333rem;
    }
    img {
      transition: transform 2s ease; /* 旋转动画持续时间和缓动函数 */
    }
    .btns {
      display: flex;
      gap: 10px;
      padding: 0.2rem ;
      border-top: 1px solid #505050;
      border-bottom: 1px solid #505050;

      button {
        flex: 1;
        padding: 0.15rem;
        background: #1c1c1c;
        color: white;
      }
    }
  }
  .second {
    padding: 0.1333rem;
    background: #303030;

    .card {
      cursor: pointer;
      padding-bottom: 0.3rem;

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 10px;
        border: 1px solid #505050;
        background: #1c1c1c;
        padding: 0.05rem 0.1rem;
        margin-bottom: 0.3rem;
      }
    }

    .password {
      border-top: 1px solid #505050;
      .confirm_btn {
        margin-top: 0.3rem;
        width: 100%;
        padding: 0.1rem 0;
        color: black;
        // cursor: not-allowed;

        &:hover {
          background: #777777;
        }
      }
    }
  }
}
.inputNum{
  background: #1c1c1c00;
  border: none;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  color: #ffffff00;
  margin-top: -50px;
  cursor: pointer;
  width: 381px;
  height: 50px;
}
//@media screen and (min-width: 768px) {
//    .inputNum{
//        width: 381px !important;
//    }
//}
.inputNum::selection {
  color: #ffffff00; /* 修改选中文字的颜色 */
  background-color: #1c1c1c00; /* 修改选中文字的背景颜色 */
}
//.inputNum:hover{
//    border: 1px solid #ffffff;
//}
::v-deep(.van-password-input__item) {
  background: #1c1c1c;
  border-right: 1px solid #505050;
  //   cursor: not-allowed;
}

::v-deep(.van-password-input) {
  i {
    background: #fff;
  }
}
::v-deep(.van-number-keyboard) {
    left: 50%;
    width: 435px;
    transform: translateX(-50%);
}
::v-deep(.van-password-input__cursor) {
    background-color:#fff !important;
}
.showzz{
    display: flex;
    justify-content: center;
    align-items: center;
    //z-index: 9999;
}
</style>
