<template>
  <div class="app">
<!--    <div v-if="showSplash" class="splash-screen">-->
<!--      <div class="popup">-->
<!--        <img src="@/assets/Rumaxlogo.png" alt="Rumax Logo" class="logo">-->
<!--      </div>-->
<!--    </div>-->
      <div v-if="showSplash"class="splash-screen">
      <div class="popup">
        <img style="margin-top: -200px" src="@/assets/BigWnr.gif" alt="Rumax Logo" class="logo">
      </div>
    </div>
    <div v-else class="container">
      <router-view
        class="router-box"
        :class="{ haveTabbar: tabbarArrPage.find((i) => i === $route.path) }"
      ></router-view>
      <Tabbar
        v-show="tabbarArrPage.find((i) => i === $route.path)"
        @show:register="(val) => (showRegister = true)"
      ></Tabbar>
      <van-dialog v-model="showRegister" :showConfirmButton="false">
        <Register
          @close:register="(val) => (showRegister = false)"
          @open:login="
            () => {
              showRegister = false
              showLogin = true
            }
          "
        ></Register>
      </van-dialog>
      <van-dialog v-model="showLogin" :showConfirmButton="false">
        <Login
          @close:login="() => (showLogin = false)"
          @show:register="
            () => {
              showLogin = false
              showRegister = true
            }
          "
        ></Login>
      </van-dialog>
      <!-- <audio src="@/assets/other/bg-music.mp3"></audio>
      <button style="display: none;">开启</button> -->
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/eventBus.js'
import Tabbar from './components/Tabbar.vue'
import Register from './components/Register.vue'
import Login from './components/Login.vue'

window.onload = function () {
    /* 对于Safari浏览器来说，无法通过meta属性限制其缩放，可以在App.vue中添加如下代码，实现缩放禁止，主要原理是：禁止两指以上的触摸事件（因为缩放需要两指操作），禁止手势事件的识别 */
    // 阻止双击放大行为
    var lastTouchEnd = 0;
    document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
            event.preventDefault();
        }
    });
    // 禁止双击出现放大镜
    document.addEventListener('touchend', function (event) {
        var now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
            event.preventDefault();
        }
        lastTouchEnd = now;
    }, false);

    // 阻止双指进行缩放大行为
    document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
    });
}

export default {
  components: { Tabbar, Register, Login },
  created() {
    this.isLogin();
    this.isInvited();

      // this.imgurl =
      //     "https://www.facebook.com/tr?id=" +
      //     this.faceBookCode +
      //     "&ev=PageView&noscript=1";
      // !(function(f, b, e, v, n, t, s) {
      //   if (f.fbq) return;
      //   n = f.fbq = function() {
      //     n.callMethod
      //         ? n.callMethod.apply(n, arguments)
      //         : n.queue.push(arguments);
      //   };
      //   if (!f._fbq) f._fbq = n;
      //   n.push = n;
      //   n.loaded = !0;
      //   n.version = "2.0";
      //   n.queue = [];
      //   t = b.createElement(e);
      //   t.async = !0;
      //   t.src = v;
      //   s = b.getElementsByTagName(e)[0];
      //   s.parentNode.insertBefore(t, s);
      // })(
      //     window,
      //     document,
      //     "script",
      //     "https://connect.facebook.net/en_US/fbevents.js"
      // );
      // fbq("init", this.faceBookCode);
      // fbq("track", "PageView");
      // fbq('track', 'AddPaymentInfo');//添加支付信息
      // fbq('track', 'AddToCart');//加入购物车
      // fbq('track', 'InitiateCheckout');//发起结账
      // fbq('track', 'Purchase',{value:0.00,currency:'USD'});//购物
      // fbq('track', 'ViewContent');//查看内容
  },
  data() {
    return {
      showSplash: true, // 添加启动页显示控制变量
      showRegister: false,
      showLogin: false,
      showCustomDialog: false, // 控制自定义确认框显示
      tabbarArrPage: [
        '/home/game',
        '/home/event',
        '/home/promote',
        '/home/notice',
        '/home/profile',
      ],
      invitedCode: undefined,
        timerId: null,
      faceBookCode: "480972771120381",//发送ajax请求动态的去获取像素代码，并复制给这个faceBookCode
      imgurl: ""
    }
  },
  mounted() {
      EventBus.$off('show-login')
      EventBus.$off('show-Register')
    EventBus.$on('show-login', () => (this.showLogin = true))
    EventBus.$on('show-Register', () => (this.showRegister = true))

      this.setupTimer();
    // setTimeout(() => {
    //   this.showSplash = false; // 5秒后隐藏启动页
    //   this.showCustomDialog = true; // 显示自定义确认框
    // }, 3000);
  },
  methods: {
      setupTimer() {
          this.clearTimer(); // 清除之前可能设置的定时器
          this.timerId = setTimeout(() => {
              this.showSplash = false; // 5秒后隐藏启动页
              this.showCustomDialog = true; // 显示自定义确认框
          }, 3000);
      },
      clearTimer() {
          if (this.timerId) {
              clearTimeout(this.timerId);
              this.timerId = null;
          }
      },
    isLogin() {
      if (localStorage.getItem('isLogin')) {
        this.$store.commit('setLogin', { isLogin: true })
      }
    },
    handleClick(val) {
      console.log(val)
    },
    isInvited() {
      if (this.$route.query.code) {
        this.showRegister = true
      }
    },
    closeCustomDialog() {
      this.showCustomDialog = false;
    }
  },
    beforeDestroy() {
        // 在组件销毁前清除定时器
        this.clearTimer();
        this.showSplash = false;
        EventBus.$off('show-login')
        EventBus.$off('show-Register')
    },
}
</script>

<style lang="scss" scoped>
.app {
  width: 100%;
  display: flex;
  justify-content: center;
  .router-box.haveTabbar {
    margin-bottom: 50px;
  }
}

.container {
  width: 435px;
}

.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //background-image: url('~@/assets/Rumax.gif'); /* 设置背景图 */
  background-image: url('~@/assets/beijing.png'); /* 设置背景图 */
  background-size: cover;
  background-position: center;
  z-index: 9999;
}
.popup {
  background-color: rgba(255, 255, 255, 0); /* 移除背景色 */
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: none; /* 移除阴影 */
}
.logo {
  //max-width: 300px;
  width: 100%;
  //margin-bottom: 60%;
}

.custom-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
  z-index: 10000;
}

.dialog-content {
  background-color: black;
  border: 2px solid gold;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  color: white;
}

.dialog-content button {
  background-color: gold;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

@media screen and (max-width: 750px) {
  .container {
    width: 100vw;
    margin: 0;
  }
}

.van-overlay {
  width: 435px !important;
  left: calc((100vw - 435px) / 2) !important;
}

::v-deep(.van-overlay) {
  width: 435px;
  left: 50%;
  transform: translateX(-50%);
}

::v-deep(.van-dialog) {
  width: 400px;
  overflow: visible;
}

@media screen and (max-width: 750px) {
  ::v-deep(.van-dialog) {
    width: 90%;
    overflow: visible;
      //z-index: 9999 !important;
  }
}
</style>
