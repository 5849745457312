<template>
  <div class="aside">
    <div class="game-menu">
      <div
        v-for="(item, index) in tabs"
        :key="index"
        class="item column-center"
        @click="toMore(index)"
      >
<!--      <div-->
<!--        v-for="(item, index) in tabs"-->
<!--        :key="index"-->
<!--        class="item column-center"-->
<!--        @click="-->
<!--          () => {-->
<!--            active = index-->
<!--            $bus.$emit('updTabs', item.dictLabel)-->
<!--            $bus.$emit('updShowMenu', false)-->
<!--          }-->
<!--        "-->
<!--      >-->
        <img :src="active === index ? item.selectedImg : item.img" />
        <span style="font-size: 8px; margin-top: 5px;">{{
          item.dictLabel
        }}</span>
      </div>
    </div>
    <div class="music">
      <div class="top">
        <img src="@/assets/images/home/前进按钮.png" />
        <img
          :src="
            require(`@/assets/images/home/${isPlay ? 'stop' : 'start'}.png`)
          "
          @click="playMusic"
        />
        <img src="@/assets/images/home/后退按钮.png" />
        <img src="@/assets/images/home/循环.png" />
        <van-badge :content="1">
          <img src="@/assets/images/home/music_playlist.png" />
        </van-badge>
      </div>
      <div class="bottom">You Spin Me Round</div>
    </div>
    <div class="record center">
      <img
        src="@/assets/images/tabs/jl.png"
        style="width: 0.2rem; height: 0.2rem; margin-right: 0.1rem;"
      />
      <div style="width: 50%; text-align: center;" @click="toUrl('/home/record?num=1')">Recordes de Apostas</div>
    </div>
    <div class="imgs">
      <div class="img" v-for="item in items" @click="toUrl2(item.name)">
        <span>{{ item.name }}</span>
        <img :src="item.imgUrl" />
      </div>
    </div>
    <div class="other-menu">
      <a-menu mode="vertical" style="background-color: #1c1c1c; border: 0;">
        <a-sub-menu key="sub1">
          <span slot="title">
            <img style="width: 24px;height: 24px;margin-right: 5px;" src="@/assets/images/home/yuyan.png" /><span
              >Português</span
            ></span
          >
            <a-menu-item key="4" @click="switchLanguage('pt')">
                Português
            </a-menu-item>
          <a-menu-item key="3" @click="switchLanguage('en')">
            English
          </a-menu-item>
        </a-sub-menu>
<!--        <a-menu-item key="1">-->
<!--          <img src="@/assets/images/home/xiazai.png" />-->
<!--          <span>APP download</span>-->
<!--        </a-menu-item>-->
        <a-menu-item key="2"  >
          <img style="width: 24px !important;height: 24px;margin-right: 5px;" src="@/assets/images/home/kefu.png" />
          <span @click="toCustomer">Customer Service</span>
        </a-menu-item>
<!--        <a-menu-item key="5">-->
<!--          <img src="@/assets/images/home/yiwen.png" />-->
<!--          <span>FAQ</span>-->
<!--        </a-menu-item>-->
      </a-menu>
<!--      <div class="login">-->
<!--        <div class="text">Quick Login</div>-->
<!--        <div class="items">-->
<!--          <div class="item">-->
<!--            <img src="@/assets/images/home/Google.png" />-->
<!--            <span>Google Login</span>-->
<!--          </div>-->
<!--          <div class="item">-->
<!--            <img src="@/assets/images/home/Facebook.png" />-->
<!--            <span>Facebook Login</span>-->
<!--          </div>-->
<!--          <div class="item">-->
<!--            <img src="@/assets/images/home/Line.png" />-->
<!--            <span>Line Login</span>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
      <van-popup v-model="showPopup" position="top" closeable style="border-radius: 10px">
          <iframe style="width:100%;min-height: 500px" :src="url" frameborder="0"></iframe>
      </van-popup>
  </div>
</template>

<script>
import { getGameTypeApi } from '../../api'
import { getHref } from '@/api/index'

export default {
  data() {
    return {
      isPlay: false,
      active: 0,
      tabs: [],
      toCustomers:"",
        showPopup:false,
        url:'https://direct.lc.chat/18478233/',
      items: [
        {
          name: 'Event',
          imgUrl:
            'https://cdntoos.win444.mx/siteadmin/skin/lobby_asset/2-1-common/common/common/btn_sy_zc_hd.png?manualVersion=1&version=1714057089629',
          url: '',
        },
        {
          name: 'Mission',
          imgUrl:
            'https://cdntoos.win444.mx/siteadmin/skin/lobby_asset/2-1-common/common/common/btn_sy_zc_rw.png?manualVersion=1&version=18929ba24b',
          url: '',
        },
        {
          name: 'Rebate',
          imgUrl:
            'https://cdntoos.win444.mx/siteadmin/skin/lobby_asset/2-1-common/common/common/btn_sy_zc_fs.png?manualVersion=1&version=1086caf33b',
          url: '',
        },
        {
          name: 'Reward',
          imgUrl:
            'https://cdntoos.win444.mx/siteadmin/skin/lobby_asset/2-1-common/common/common/btn_sy_zc_jl.png?manualVersion=1&version=c4e9f312d4',
          url: '',
        },
        {
          name: 'History',
          imgUrl:
            'https://cdntoos.win444.mx/siteadmin/skin/lobby_asset/2-1-common/common/common/btn_sy_zc_lqjl.png?manualVersion=1&version=c1a0a30e23',
          url: '',
        },
        // {
        //   name: 'Provident Fund',
        //   imgUrl:
        //     'https://cdntoos.win444.mx/siteadmin/skin/lobby_asset/2-1-common/common/common/btn_sy_zc_gjj.png?manualVersion=1&version=451eebdfc6',
        //   url: '',
        // },
        // {
        //   name: 'Interest',
        //   imgUrl:
        //     'https://cdntoos.win444.mx/siteadmin/skin/lobby_asset/2-1-common/common/common/btn_sy_zc_yeb.png?manualVersion=1&version=3f1f642bba',
        //   url: '',
        // },
        {
          name: 'VIP',
          imgUrl:
            'https://cdntoos.win444.mx/siteadmin/skin/lobby_asset/2-1-common/common/common/btn_sy_zc_vip.png?manualVersion=1&version=97f478aef2',
          url: '',
        },
        {
          name: 'Agent',
          imgUrl:
            'https://cdntoos.win444.mx/siteadmin/skin/lobby_asset/2-1-common/common/common/btn_sy_zc_tgzq.png?manualVersion=1&version=4a7b096dd6',
          url: '',
        },
      ],
      audio: null,
    }
  },
  created() {
    this.initData()
      this.getByLabel();
  },
  mounted() {
    this.audio = document.querySelector('audio')
  },
  methods: {
      getByLabel(){
          getHref("toCustomer").then(res=>{
              this.toCustomers = res.msg;
          })
      },
    toCustomer(){
        // console.log(this.toCustomers)
        window.location.href = this.toCustomers;
          // window.location.href = 'https://t.me/bigwnr6666';
        // this.showPopup = true
    },
    switchLanguage(lang) {
      console.log(lang);
      this.$i18n.locale = lang;
    },
    async initData() {
      const { data: data } = await getGameTypeApi()
      this.tabs = data
    },
    playMusic() {
      this.isPlay = !this.isPlay
      this.isPlay ? this.audio.play() : this.audio.pause()
    },
    toUrl(url){
      this.$router.push(url)
    },
    toUrl2(name){
      // console.log("clickName:"+name)
      if (name =="Event"){
        this.$router.push('/home/event')
      }else if (name =="Mission"){
        // this.$router.push('/home/task')
      }else if (name =="Rebate"){
        this.$router.push('/home/cashback')
      }else if (name =="Reward"){
        this.$router.push('/home/canReceive')
      }else if (name =="History"){
        this.$router.push('/home/records')
      }else if (name =="Provident Fund"){
        this.$router.push('/home/rechargeFund')
      }else if (name =="Interest"){
        this.$router.push('/home/yuebao')
      }else if (name =="VIP"){
        this.$router.push('/home/vip')
      }else if (name =="Agent"){
        this.$router.push('/home/promote')
      }
    },
      toMore(index){
          this.$router.push('/home/subgame?num='+index)
      },
  },
}
</script>

<style lang="scss" scoped>
.aside {
  width: 2.4rem;
  padding: 0.15rem 0.15rem 0;
  background-color: #1c1c1c;
  //padding-bottom: 1rem;
  padding-bottom: 3rem;
  margin-right: -1px;
  color: #c7c7c7;

  .game-menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    width: 100%;
    margin-bottom: 0.1rem;

    .item {
      height: 0.7rem;
      border-radius: 0.1rem;
      background-color: #303030;
      color: var(--text-color);
      cursor: pointer;
      img {
        height: 0.3rem;
      }
    }
  }

  .music {
    height: 0.6rem;
    padding: 0.04rem 0.1rem;
    border-radius: 0.1rem;
    background-color: #303030;
    margin-bottom: 0.1rem;
    cursor: pointer;
    text-align: center;
  }

  .top {
    img {
      width: 0.2rem;
      height: 0.2rem;
      margin-right: 0.1rem;
    }
  }

  .record {
    height: 0.6rem;
    border-radius: 0.1rem;
    background-color: #303030;
    margin-bottom: 0.1rem;
  }

  .imgs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #303030;
    padding: 0.1rem 0.1rem;
    border-radius: 0.1rem;
    margin: 0.1rem 0px 0.05rem;
    gap: 5px;

    .img {
      flex: 1;
      position: relative;
      cursor: pointer;
      span {
        display: inline-block;
        position: absolute;
        width: 0.56rem;
        left: 0.08rem;
        top: 0.08rem;
        color: #fff;
        font-size: 9.89774px;
      }

      img {
        height: 0.6rem;
      }

      &:last-child {
        grid-column: span 2;
        flex: 2;
        position: relative;
        span {
          position: absolute;
          left: 30%;
          top: 50%;
          transform: translateY(-50%);
        }
        img {
          width: 100%;
        }
      }
    }
  }

  .other-menu {
    span:hover {
      color: var(--yellow);
    }
  }

  .login {
    .text {
      font-size: 0.18rem;
      margin: 0.15rem 0px 0px 0.07rem;
    }

    .items {
      .item {
        display: flex;
        align-items: center;
        background-color: #303030;
        border-radius: 0.1rem;
        cursor: pointer;
        font-size: 0.18rem;
        height: 0.4rem;
        margin-top: 0.1rem;
        img {
          margin: 0 0.1rem;
          width: 0.3rem;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .aside {
    margin-left: 20px;
  }
}
</style>
