<template>
  <div class="container">
    <header style="margin-top: 10px;">
      <van-icon
        name="arrow-left"
        size="0.3rem"
        class="left_btn"
        @click="$emit('close-summary')"
        style="cursor: pointer;"
      />
      <h2>Registro de recarga</h2>
    </header>
    <div class="top between">
      <!-- <a-select
        ref="select"
        v-model="sj"
        size="small"
        :dropdownMatchSelectWidth="false"
        @focus="focus"
        @change="handleChange"
      >
        <a-select-option value="Hoje" style="z-index: 10000;"
          >Hoje</a-select-option
        >
        <a-select-option value="Ontem">Ontem</a-select-option>
        <a-select-option value="Últimos 7 Dias">Últimos 7 Dias</a-select-option>
        <a-select-option value="Últimos 15 Dias"
          >Últimos 15 Dias</a-select-option
        >
        <a-select-option value="Últimos 30 Dias"
          >Últimos 30 Dias</a-select-option
        >
        <a-select-option value="Personalizar">Personalizar</a-select-option>
      </a-select> -->
      <!-- <span>
        <span>Total de Saques</span>
        <span style="color: white; margin-left: 0.1rem;">R$ 0,00</span>
      </span> -->
    </div>
    <van-list
      v-model="loading"
      :finished="finished"
      loading-text="Carregamento..."
      finished-text="Não sobrou mais nada."
      @load="getTableList()"
    >
      <div class="items">
        <div class="item" v-for="(item, index) in arr" :key="index">
          <div class="between">
            <div>
              <img
                src="@/assets/images/other/icon_normal_pix.png"
                class="small-button"
              />
              <span style="margin-left: 0.1rem;">PIX (Quente)</span>
            </div>
            <div>R$ {{ item.amount }}</div>
          </div>
          <div class="between">
            <div style="display: flex;width: 100%;justify-content: space-between;align-items: center;">
              <div >
                <span style="color: #777;">{{ item.updateTime }}</span>
                <div>
                  <span style="color: #777;margin-right: 5px;">{{ item.id }}</span>
                  <img
                      style="width: 0.2rem; height: 0.2rem;"
                      src="@/assets/images/my/复制.png"
                  />
                </div>
              </div>
              <span style="color: red; margin: 0 0.1rem;"v-if="item.status ==0">fallar</span>
              <span style="color: #56cb49; margin: 0 0.1rem;"v-else>éxito</span>
            </div>
            <!-- <div style="color: #ea4e3d; cursor: pointer;" @click="show = true">
            Pedido Expirado
          </div> -->
          </div>
        </div>
      </div>
    </van-list>
    <!-- 过期信息 -->
    <van-dialog v-model="show" :showConfirmButton="false" class="dialog">
      <header class="center">
        <div>Detalhes do Depósito</div>
        <van-icon name="cross" class="icon" @click="show = false" />
      </header>
      <main>
        <div class="column-center">
          <img src="@/assets/images/other/warning.png" class="warning" />
          <span
            style="
              color: var(--error);
              font-size: small;
              margin-bottom: 0.1rem;
            "
            >Pedido Expirado</span
          >
          <span style="font-size: x-large; font-weight: bolder;">R$ 10,00</span>
        </div>
        <div class="info">
          <div class="item between">
            <div style="color: #777;">Tipo de transação</div>
            <div style="color: #e3e3e3;">Depósito</div>
          </div>
          <div class="item between">
            <div style="color: #777;">Método de Recarga</div>
            <div style="color: #e3e3e3;">PIX (Quente)</div>
          </div>
          <div class="item between">
            <div style="color: #777;">Canal de recarga</div>
            <div style="color: #e3e3e3;">BetcatPay&BCPay</div>
          </div>
          <div class="item between">
            <div style="color: #777;">Canal de recarga</div>
            <div style="color: #e3e3e3;">BetcatPay&BCPay</div>
          </div>
          <div class="item between">
            <div style="color: #777;">Hora de Criação</div>
            <div style="color: #e3e3e3;">29/04/2024 13:09:41</div>
          </div>
          <div class="item between">
            <div style="color: #777;">Número do Pedido</div>
            <div style="color: #e3e3e3;">
              <span>2115800050625651</span>
              <img
                style="width: 0.2rem; height: 0.2rem; margin-left: 0.1rem;"
                src="@/assets/images/my/复制.png"
              />
            </div>
          </div>
        </div>
      </main>
    </van-dialog>
  </div>
</template>

<script>
import { getDepositListApi } from '../../api'
export default {
  data() {
    return {
      sj: 'Hoje',
      show: false,
      arr: [],
      form: {
        pageNum: 0,
        pageSize: 10,
      },
      loading: false,
      finished: false,
    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    async getTableList() {
      this.loading = true
      this.form.pageNum += 1
      console.log(this.form.pageNum)
      const resp = await getDepositListApi(this.form)
      this.arr = [...this.arr, ...resp.rows]
      this.loading = false
      if (this.arr.length >= resp.total) {
        // console.log('结束:'+this.arr)
        console.log('data:'+this.arr)
        this.finished = true
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.top {
  padding: 0.1rem;
}

header {
  position: relative;
  text-align: center;
  .left_btn {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  h2 {
    color: #e3e3e3;
  }
}

.items {
  padding: 0.2rem 0.1rem;
  .item {
    padding: 0.2rem;
    border-radius: 10px;
  }
  .item:nth-child(odd) {
    background-color: #303030;
  }
}

.dialog {
  header {
    background: var(--dark-bg);
    padding: 0.1rem 0;
    .icon {
      position: absolute;
      cursor: pointer;
      right: 0.1rem;
      top: 0.1rem;
    }
  }
  main {
    background: #313131;
    .warning {
      width: 0.7758rem;
      height: 0.7758rem;
      margin-bottom: 0.2rem;
    }
    .info {
      margin: 0 0.1rem;
      margin-top: 0.1rem;
      border-top: 1px dotted var(--boder-color);
      font-size: x-small;
      .item {
        padding: 0.05rem 0;
      }
    }
  }
}
</style>
