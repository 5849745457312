<!--<head>-->
<!--...-->
<!--&lt;!&ndash; Facebook Pixel Code &ndash;&gt;-->
<!--<meta-->
<!--    name="facebook-domain-verification"-->
<!--    content="FB_DOMAIN_VERIFICATION_CODE_HERE_STATICALLY"-->
<!--/>-->
<!--<script>-->
<!--  !(function(f, b, e, v, n, t, s) {-->
<!--    if (f.fbq) return;-->
<!--    n = f.fbq = function() {-->
<!--      n.callMethod-->
<!--          ? n.callMethod.apply(n, arguments)-->
<!--          : n.queue.push(arguments);-->
<!--    };-->
<!--    if (!f._fbq) f._fbq = n;-->
<!--    n.push = n;-->
<!--    n.loaded = !0;-->
<!--    n.version = "2.0";-->
<!--    n.queue = [];-->
<!--    t = b.createElement(e);-->
<!--    t.async = !0;-->
<!--    t.src = v;-->
<!--    s = b.getElementsByTagName(e)[0];-->
<!--    s.parentNode.insertBefore(t, s);-->
<!--  })(-->
<!--      window,-->
<!--      document,-->
<!--      "script",-->
<!--      "https://connect.facebook.net/en_US/fbevents.js"-->
<!--  );-->
<!--  fbq("init", "480972771120381");-->
<!--  fbq("track", "PageView");-->
<!--</script>-->
<!--<noscript-->
<!--&gt;<img-->
<!--    height="1"-->
<!--    width="1"-->
<!--    style="display:none"-->
<!--    src="https://www.facebook.com/tr?id=480972771120381&ev=PageView&noscript=1"-->
<!--/></noscript>-->
<!--&lt;!&ndash; End Facebook Pixel Code &ndash;&gt;-->
<!--</head>-->
<template>
  <div class="game">
    <a-spin :spinning="isLoading" tip="Loading...">
      <Header @show-menu="(val) => (showMenu = val)"></Header>
      <van-popup
        v-model="showMenu"
        position="left"
        style="
          margin-top: 0.6061rem;
          left: calc((100vw - 435px) / 2) !important;
        "
        name="van-slide-left"
        :duration="0"
      >
        <Aside></Aside>
      </van-popup>
      <Main @set-loading="(val) => (isLoading = val)"></Main>
      <Footer></Footer>
    </a-spin>
  </div>
</template>

<script>
import Header from './Header.vue'
import Aside from './Aside.vue'
import Main from './Main.vue'
import Footer from './Footer'
import Tabbar from '../../components/Tabbar.vue'
export default {
  components: { Header, Aside, Main, Footer, Tabbar },
  data() {
    return {
      showMenu: false,
      isLoading: false,
    }
  },
  created() {},
  methods: {
    getContainer() {
      return document.querySelector('.container')
    },
  },
}
</script>



<style lang="scss" scoped>
.game {
  position: relative;
}
.van-popup::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}

::v-deep(.van-overlay) {
  margin-top: 50px;
}
</style>
