//导入axios
import axios from 'axios'
import Router from '@/router'
import { Toast } from 'vant'
import store from '../store'
import { EventBus } from './eventBus'

// const baseURL = 'http://172.20.10.10:8080'
// const baseURL = 'http://localhost:8080'
// const baseURL = 'https://www.rumaxbet.com/prod-api'
const baseURL = 'https://bigwnr.com/prod-api'
const instance = axios.create({
  baseURL,
  headers: {
    // 'Content-Language': 'zh_CN',
    'Content-Language': 'pt_PT',
  },
})

//axios设置请求拦截器
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (err) => {
    console.log(err)
  }
)

instance.interceptors.response.use((response) => {
  const data = response.data
  if (data.code === 401) {
    Router.push('/')
    store.dispatch('logout')
    // Toast.fail('Por favor, faça o login primeiro')
    //   EventBus.$emit('show-login')
  }
  return data
})
export default instance
