<template>
  <div style="color: red;">
    <div class="home-icon column-center" @click="$router.push('/')">
      <van-icon name="wap-home" :size="25" />
      <div>Lobby</div>
    </div>
    <iframe
      style="width: 100%; height: 100vh; border: none;"
      :src="url"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: undefined,
    }
  },
  created() {
    this.url = this.$route.query.url
  },
}
</script>

<style lang="scss" scoped>
.home-icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 50%;
  background: #090d11;
  border: 1px solid var(--grey-border);
}
</style>
