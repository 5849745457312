import Vue from 'vue'
import { Toast } from 'vant'
import Vuex from 'vuex'
import { loginApi, getUserInfoApi } from '@/api'
import Router from '@/router'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    isLogin: JSON.parse(localStorage.getItem('userInfo')) || false,
    userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
  },
  mutations: {
    updateBalance(state,res){
      state.userInfo = res.data
      // console.log(state.userInfo);
    },
    setLogin(state, payload) {
      state.isLogin = payload.isLogin
      if (payload.userInfo) {
        state.userInfo = payload.userInfo.data
      }
    },
  },
  actions: {
    // async login({ commit }, form) {
    //   let isOk = false
    //   const resp = await loginApi(form)
    //   if (resp.code === 500) {
    //     Toast.fail(resp.msg)
    //   } else {
    //     Toast.success('Login bem sucedido')
    //     localStorage.setItem('token', resp.data.token)
    //     localStorage.setItem('isLogin', true)
    //     const { data: userInfoData } = await getUserInfoApi()
    //     console.log(userInfoData)
    //     localStorage.setItem('userInfo', JSON.stringify(userInfoData))
    //     commit('setLogin', { isLogin: true, userInfo: userInfoData })
    //     isOk = true
    //   }
    //   return isOk
    // },
    async login({ commit }, form) {
      // try {
        const resp = await loginApi(form);
        if (resp.code !== 200) { // 假设200是成功的code
            // console.log('fail')
            Toast.fail(resp.msg)
            localStorage.setItem('isLogin', false);
          throw new Error(resp.msg);
        }else {
            const token = resp.data.token;
            localStorage.setItem('token', token);
            localStorage.setItem('isLogin', true);
            const userInfoData = await getUserInfoApi();
            localStorage.setItem('userInfo', JSON.stringify(userInfoData));
            Toast.success('Login bem sucedido')
            commit('setLogin', { isLogin: true, userInfo: userInfoData });
            return Promise.resolve(true); // 返回一个成功的Promise
        }
      // } catch (error) {
      //     // console.log(error)
      //     Toast.fail(222); // 捕获并显示错误
      //   return Promise.reject(error); // 返回一个失败的Promise
      // }
    },
    logout({ commit }) {
      commit('setLogin', { isLogin: false, userInfo: {} })
      localStorage.clear()
      return Router.push('/home/game')
      // Toast.success('Saída bem sucedida')
    },
  },
  modules: {},
})
