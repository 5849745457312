import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home/index.vue'
import Event from '@/views/Event.vue'
import promocao from '@/views/promocao'
import WithDraw from '@/views/withdraw/index.vue'
import Deposit from '@/views/deposit/index.vue'
import GameDetail from '@/views/home/Detail.vue'
import subGame from '@/views/slots/index.vue'
import Registrodetail from '@/views/withdraw/Registrodetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home/game',
    component: Home,
    children: [
      {
        name: 'Home',
        path: '/home/game',
      },
    ],
  },
  {
    path: '/event',
    component: promocao,
    redirect: '/home/event',
    children: [
      {
        path: '/home/event',
        name: 'Eventos',
        component: () => import('@//views/promocao/Eventos.vue'),
      },
      {
        path: '/home/task',
        name: 'Missao',
        component: () => import('@/views/promocao/Missao.vue'),
      },
      {
        path: '/home/records',
        name: 'Historico',
        component: () => import('@/views/promocao/Historico.vue'),
      },
      {
        path: '/home/vip',
        name: 'Vip',
        component: () => import('@/views/promocao/Vip.vue'),
      },
      {
        path: '/home/cashback',
        name: 'Rebate',
        component: () => import('@/views/promocao/Rebate.vue'),
      },
      {
        path: '/home/yuebao',
        name: 'Juros',
        component: () => import('@/views/promocao/Juros.vue'),
      },
      {
        path: '/home/rechargeFund',
        name: 'Fundo de Previdência',
        component: () => import('@/views/promocao/FDP.vue'),
      },
      {
        path: '/home/canReceive',
        name: 'Pendente',
        component: () => import('@/views/promocao/Pendente.vue'),
      },
    ],
  },
  {
    path: '/event/item',
    name: 'EventItem',
    component: () => import('@/views/EventItem.vue'),
  },
  {
    path: '/event/item2',
    name: 'EventItem2',
    component: () => import('@/views/EventItem2.vue'),
  },
  {
    path: '/event/item3',
    name: 'EventItem3',
    component: () => import('@/views/EventItem3.vue'),
  },
  {
    path: '/event/item4',
    name: 'EventItem4',
    component: () => import('@/views/EventItem4.vue'),
  },
  {
    path: '/event/item11',
    name: 'EventItem11',
    component: () => import('@/views/EventItem11.vue'),
  },
  {
    path: '/event/item12',
    name: 'EventItem12',
    component: () => import('@/views/EventItem12.vue'),
  },
  {
    path: '/event/item13',
    name: 'EventItem13',
    component: () => import('@/views/EventItem13.vue'),
  },
  {
    path: '/event/item14',
    name: 'EventItem14',
    component: () => import('@/views/EventItem14.vue'),
  },
  {
        path: '/event/item15',
        name: 'EventItem15',
        component: () => import('@/views/EventItem15.vue'),
  },
  {
    path: '/home/notice',
    name: 'notice',
    component: () => import('@/views/notice/index.vue'),
  },
  {
    path: '/home/promote',
    name: 'Agente',
    component: () => import('@/views/agent/index.vue'),
  },
  {
    path: '/home/profile',
    name: 'profile',
    component: () => import('@/views/my/index.vue'),
  },
  {
    path: '/home/record',
    name: 'record',
    component: () => import('@/views/my/Record.vue'),
  },
  {
    path: '/home/setting',
    name: 'Setting',
    component: () => import('@/views/my/Setting.vue'),
  },
  {
    path: '/home/payPassword',
    name: 'payPassword',
    component: () => import('@/views/my/PayPassword.vue'),
  },
  {
    path: '/home/withdraw',
    name: 'WithDraw',
    component: WithDraw,
  },
  {
    path: '/home/registrodetail',
    name: 'Registrodetail',
    component: Registrodetail,
  },
  {
    path: '/home/deposit',
    name: 'Deposit',
    component: Deposit,
  },
  {
    path: '/home/subgame',
    name: 'subGame',
    component: subGame,
  },
  {
    path: '/home/game/detail',
    name: 'GameDetail',
    component: GameDetail,
  },
]

// 路由重复报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
