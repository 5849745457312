<template>
  <div class="game">
<!--    <div class="swiper">-->
<!--      <a-carousel arrows :autoplay="true">-->
<!--        <div slot="prevArrow" class="custom-slick-arrow" style="left: 10px; z-index: 1;">-->
<!--          <a-icon type="left-circle" style="color: white !important;" />-->
<!--        </div>-->
<!--        <div slot="nextArrow" class="custom-slick-arrow" style="right: 10px;">-->
<!--          <a-icon type="right-circle" />-->
<!--        </div>-->
<!--        <div v-for="(item,index) in lunboList" :key="index">-->
<!--          <div :data="index" class="lunboCss" v-html="item.noticeContent" @click="toTele(index)"></div>-->
<!--        </div>-->
<!--      </a-carousel>-->
<!--    </div>-->
    <van-swipe class="swiper" :autoplay="3000" indicator-color="white">
      <van-swipe-item  v-for="(item,index) in lunboList" :key="index" @click="gotoItem(item)">
        <div>
          <div :data="index" class="lunboCss" v-html="item.noticeContent" ></div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="message center">
      <img src="@/assets/images/home/message.png" />
      <div class="content">
        <van-notice-bar text="📢📢📢📢Jogue caça-níqueis/jogos de mesa de pesca e aproveite umdescontode"
          style="height: 20px; background-color: transparent; padding: 0;" />
        <!--        <van-notice-bar style="height: 20px; background-color: transparent; padding: 0;-->
        <!--        white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" v-for="item in gdList">-->
        <!--          {{ item.noticeContent }}-->
        <!--        </van-notice-bar>-->
        <div class="scroll-text" v-if="isScrolling">

        </div>
      </div>
      <img src="@/assets/images/home/tu06.png" style="cursor:pointer;"  @click="$router.push('/home/notice?active=3')" />
    </div>
    <div class="card" ref="contaniner">
      <van-tabs :lazy-render="false" v-model="active" ref="tabs" scrollspy sticky
        title-active-color="#144a9e" offset-top="50">
        <van-tab v-for="(item, index) in tabs" :key="index">
          <template  #title>
            <a class="tab-item column-center" @click="toSubGame(index)">
              <img  :src="active === index ? item.selectedImg : item.img"/>
              <span >{{ item.dictLabel }}</span>
            </a>
          </template>
          <div class="item" v-if="item.arr.length!=0 || item.dictLabel=='Recent' || item.dictLabel=='Favoritos' ">
            <span class="title" style="display: flex;justify-content: space-between">
              <div>
                <img :src="item.selectedImg" />
              <span class="tab-name" style="color: #fff;">
                {{ item.dictLabel }}
              </span>
              </div>
              <span @click="toMore(index)" style="cursor:pointer;">Tudo</span>
            </span>
            <div class="content">
              <div v-if="index === 0" class="total">
                <!-- {{ getRandomNum | formatNumber }} -->
                <VueCountUp style="color: #fdf468 !important;" v-if="!isUpdating" :start-value="0" :end-value="initialNum" :duration="1" :options="options"/>
                <div style="color: #fdf468;" v-else>{{ number | formatNumber }}</div>
              </div>
              <div class="games not-circle">
                <div class="games-item" v-for="i in item.arr">
                  <img @click="getGameDetali(i.id)" :src="
                      i.imageurlRectangleEn
                        ? i.imageurlRectangleEn
                        : i.imageurlSquareEn
                    " />
                  <div style="
                      position: absolute;
                      bottom: 0;
                      width: 100%;
                      display: flex;
                      text-align: center;
                      line-height: 1;
                      flex-direction: column;
                      padding-bottom: 8px;
                    ">
                    <span style="
                        display: block;
                        width: 80%;
                        text-align: center;
                        margin: 0 auto;
                        color: #fff;
                        font-size: 12px;
                        padding-bottom: 8px;
                      ">
                      {{ i.gamenamePt }}
                    </span>
                    <span style="color: #fff; font-size: 10px;">
                      {{ i.plattype }}
                    </span>
                  </div>
                  <div class="star" @click="handleCare(i)">
                    <img :src="
                        require(`@/assets/images/home/star${
                          i.isFavorite ? '-active' : ''
                        }.png`)
                      " :style="{ transform: i.isFavorite ? 'scale(2)' : '' }" />
                  </div>
                </div>
              </div>
              <div class="more" @click="
                  () => {
                    item.form.pageSize =
                      item.form.pageSize + (isCircle ? 9 : 12)
                    getGameList(item)
                  }
                ">
                <div class="info">
                  A exibir {{ item.arr.length }} jogos entre
                  {{ item.total }} Popular jogos
                </div>
                <div class="btn" v-if="item.arr.length < item.total">
                  Carregar mais<van-icon name="arrow-down" style="margin-left: 0.1rem;" />
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <van-popup v-model="show1" class="GG_tc">
      <div style="display: flex;justify-content: flex-end;padding-right: 10px;">
        <img style="margin: 10px 0 0 10px" @click="clsoePup" src="@/assets/close.png" alt="">
      </div>
      <div style="display:flex;height: 80%;">
        <div style="width: 20%;border-right: 1px solid;min-height: 300px;margin-right: 10px">
          <div v-for="(item, index) in noticeList" :key="index">
            <div @click="selectItem(index,item)"
              :class="{ 'div_contentTitle': true, 'active_cTitle': selectedIndex === index }">
              {{ item.noticeTitle }}
            </div>
          </div>
        </div>
        <div style="width:80%;overflow-y: auto;min-height: 300px;">
          <div class="noticeContent" style="width:100%;" @click="toBox()" v-html="noticeContent"></div>
        </div>
      </div>
      <!-- </div> -->
    </van-popup>
      <van-popup v-model="showPopup"  position="center" closeable style="border-radius: 10px">
          <iframe style="width:100%;min-height: 500px" :src="url" frameborder="0"></iframe>
      </van-popup>
    <img class="icon_fj" :style="iconFjRight" src="../../assets/kf.png" alt="" @click="toChannel()">
    <img class="icon_fj2" :style="iconFjRight" src="../../assets/baoxiang.png" alt="" @click="toChannel2()">
    <img class="icon_fj3" :style="iconFjRight" src="../../assets/hongbao.png" alt="" @click="toChannel3()">
  </div>
</template>

<script>
import {
  careGameApi,
  getGameListApi,
  getGameTypeApi,
  visitGameApi,
  getHistoryGameListApi,
  getCareGameListApi,
  getUserInfoApi,
} from '../../api'
import { EventBus } from '@/utils/eventBus.js'
import { getByType,getHref } from '@/api/index'
import { Popup } from 'vant'
import VueCountUp from 'vue-countupjs' //引入

export default {
  data() {
    return {
        showPopup: false,
        url:'https://direct.lc.chat/18478233/',
        isUpdating: false,
        initialNum: null,
        options: {
            useEasing: true, // 缓动动画 easing
            useGrouping: true, // 1,000,000 vs 1000000
            separator: ',', // 数字分隔符
            decimal: '.', // 小数分隔符
            prefix: '', // 前缀
            suffix: '' // 后缀
        },
        intervalId: null,
        intervalBalance: null,
        active: 0,
        tabs: [],
        arr: [],
        total: 0,
        isCircle: true, // 长方形：true
        number: 1000000000,
        getRandomNum: '',
        noticeList: '',//公告
        show1: false,

        iconFjRight: '',
        noticeContent: '',
        gdList: '',//滚动公告
        text: "这是滚动的文字示例，可以根据需要替换内容XXXXXXXXXX。",
        isScrolling: true,
        selectedIndex: 0, // 默认第一条被选中
        lunboList: '',
        joinTelegramGroup: '',
        getBalance: '',
        getIsStaff:'',
        getStatus: '',
        timerId:null
    }
  },
  computed: {
    randomNumber() {
      return this.number.toLocaleString() // 格式化数字
    },
    isLogin() {
      return this.$store.state.isLogin;
    },
  },
  async created() {
      this.getByLabel();
    await this.initData();
    this.$bus.$on('updTabs', (res) => {
      for (let i = 0; i < this.tabs.length; i++) {
        let tab = this.tabs[i]
        if (tab.dictLabel == res) {
          this.active = i
          break
        }
      }
      this.$refs.tabs.scrollTo(this.active)
    })
  },
  beforeDestroy() {
    // 在组件销毁前清除定时器
    clearInterval(this.intervalId);
    clearInterval(this.intervalBalance);
      if (this.timerId) {
          clearTimeout(this.timerId);
          this.timerId = null;
      }
  },
  mounted() {
// 从localStorage获取或生成随机数，并设置initialNum和number
    this.number = localStorage.getItem('randomNum')
      ? parseInt(localStorage.getItem('randomNum'))
      : Math.floor(Math.random() * 100000); // 假设您想要一个0到100000之间的数
    this.initialNum = this.number; // 设置initialNum用于VueCountUp组件的end-value

    // 开始VueCountUp动画
    this.isUpdating = false;
    this.timerId = setTimeout(() => {
      this.isUpdating = true
    }, 3000);

    // 设置定时器更新数值（但不会重新触发VueCountUp的滚动）
    this.intervalId = setInterval(() => {
      this.number = (this.number + Math.floor(Math.random() * 100000)) % 1000000000; // 更新数值，但不超过1000000000
      localStorage.setItem('randomNum', this.number); // 将新数值保存到localStorage
    }, 2000); // 每2秒执行一次

    // 检查本地存储中是否存在名为 "popupShown" 的项
    const aaa = localStorage.getItem("show1");

    // 如果不存在，则将其设置为 false
    if (!aaa) {
      // 设置 "popupShown" 为 true，表示弹窗已经弹出过
      localStorage.setItem("show1", "true");
      // 显示弹窗

      if (this.$route.query.code==undefined){
        this.show1 = true;
      }else {
        this.show1 = false;
      }
    }else{
      this.show1 = false;
    }
    console.log("show1:"+this.show1)
    this.getByType();
    this.getByType2();
    this.getByType3();
    this.iconFjRightFun();
    // this.getMoney();
    // this.startScroll();
    //   this.intervalBalance = setInterval(() =>{
    //       this.getMoney();
    //   },1000)

  },
  methods: {
      gotoItem(item) {
          console.log(item)
          if(item.noticeTitle =="轮播-加入飞机"){
              window.location.href = "https://t.me/bigwnr6666";
              console.log('00000')
          }else if(item.noticeTitle =="轮播-救济金"){
              console.log('11111')
              this.$router.push('/event/item3?id=1792087515468288001')
          }else if(item.noticeTitle =="轮播-每日累计充值"){
              this.$router.push('/event/item4?id=1792104082419646465')
          }else if(item.noticeTitle == "轮播-宝箱活动"){
              this.$router.push('/event/item?id=1792104528932667393')
          }else if(item.noticeTitle == "轮播-VIP等级"){
              this.$router.push('/home/vip')
          }else if(item.noticeTitle == "轮播-每日签到"){
              this.$router.push('/event/item13?id=1795077576968708097')
          }else if(item.noticeTitle == "轮播-累计投注"){
              this.$router.push('/event/item12?id=1814594373002784769')
          }else if(item.noticeTitle == "轮播-红包"){
              this.$router.push('/home/profile?show=true')
          }
      },
    onAnimationEnd () {},
    iconFjRightFun() {
      if (window.innerWidth > 435) {
        this.iconFjRight = 'right: calc(50vw - 175px);'
      } else {
        this.iconFjRight = 'right: calc(50vw - 180px);'
      }
    },
    getByType(){
      getByType(3).then(res=>{
        this.noticeList = res.data;
        if(res.data) {
          this.noticeContent = res.data[0].noticeContent
        }
      })
    },
    getByLabel(){
        getHref("toCustomer").then(res=>{
            this.joinTelegramGroup = res.msg;
        })
    },
      toChannel(){
          window.location.href = this.joinTelegramGroup;
            // window.location.href = 'https://direct.lc.chat/18478233/';
            // window.location.href = 'https://t.me/bigwnr6666';
          // this.showPopup = true
      },
      toChannel2(){
          if (!this.$store.state.isLogin) {
              return EventBus.$emit('show-login')
          }
          this.$router.push('/event/item?id=1792104528932667393')
      },
      toChannel3(){
          if (!this.$store.state.isLogin) {
              return EventBus.$emit('show-login')
          }
          this.$router.push('/home/profile?show=true')
      },
    toTele(index){
      if (index == 0){
        this.toChannel()
      }
    },
    toMore(index){
      this.$router.push('/home/subgame?num='+index)
    },
    toSubGame(index){
          if(index>=2&&index<=5){this.$router.push('/home/subgame?num='+index)}
    },
    getByType2(){
      getByType(5).then(res=>{
        this.gdList = res.data;
      })
    },
    getByType3(){
      getByType(1).then(res=>{
        this.lunboList = res.data;
      })
    },
    selectItem(index,item) {
      this.selectedIndex = index; // 更新选中索引
      this.noticeContent = item.noticeContent
    },
      toBox(){
          if (!this.$store.state.isLogin) {
              this.show1 = false
              return EventBus.$emit('show-login')
          } else{
              this.$router.push('/event/item?id=1792104528932667393')
          }
      },
    startScroll() {
      const container = this.$refs.container;
      const textWidth = container.offsetWidth;
      const scrollWidth = container.scrollWidth;

      if (scrollWidth > textWidth) {
        const duration = scrollWidth * 20; // 根据文字长度动态计算滚动时间
        container.style.animation = `scroll ${duration}ms linear infinite`;
      } else {
        this.isScrolling = false; // 如果文本没有溢出，停止滚动
      }
    },
    clsoePup(){
      this.show1 = false
    },
    async initData() {
      await this.getGameType()
      await this.setisCircle()
      this.tabs.forEach((i) => {
        this.getGameList(i)
      })
      if (this.isLogin) {
        this.getHistoryGameList()
        this.getCareGameList()
      }
    },
    async getGameType() {
      const { data: data } = await getGameTypeApi()
      const newArr = data.map((i) => {
        return {
          img: i.img,
          selectedImg: i.selectedImg,
          dictLabel: i.dictLabel,
          form: {
            gametype: i.dictValue,
            // ismain: 0,
            pageNum: 1,
            pageSize: this.circle ? 9 : 12,
          },
          total: 0,
          arr: [],
        }
      })
      this.tabs = newArr
    },
    async getGameList(item) {
      const data = await getGameListApi(item.form)
      //   const isCircle = data.rows[0].imageurlCircleEn ? true : false
      item.total = data.total
      item.arr = data.rows
    },
      getMoney(){
          getUserInfoApi().then(res=>{
              if (res.code==200){
                  this.getBalance = res.data.balance
                  this.getStatus = res.data.status
                  this.getIsStaff = res.data.isStaff
                  console.log("余额："+this.getBalance)
              }
          })
      },
    async getGameDetali(code) {
          this.getMoney();
        if (!this.$store.state.isLogin) {
            return EventBus.$emit('show-login')
        }
          if (this.getStatus ==4) {
              this.$toast.fail("O jogo está temporariamente indisponível, entre em contato com o atendimento ao cliente.")
              return;
          }

          // if(this.$store.state.userInfo.isStaff!=0){
          //     this.$toast.fail("Prezado(a) blogueiro(a), você não tem permissão para entrar no jogo.")
          //     return
          // }
        if (this.$store.state.userInfo.balance<3){
            // if (this.getIsStaff!=0){
            // }else {
                this.$toast.fail("Seu saldo  menor que R$3, Por favor.faca um deposito para jogar")
                return;
            // }
        }
      const { data: data,msg: msg } = await visitGameApi(code)
      if (data) {
        this.$router.push({ path: '/home/game/detail', query: { url: data } })
      }
    },
    async setisCircle() {
      const { rows: data } = await getGameListApi(this.tabs[0].form)
      this.isCircle = data[0].imageurlCircleEn ? true : false
    },
    async handleCare(item) {
      if (!this.$store.state.isLogin) {
        return EventBus.$emit('show-login')
      }
      await careGameApi(item.id)
      await this.getCareGameList()
      item.isFavorite = !item.isFavorite
    },
    async getHistoryGameList() {
      const recentTab = this.tabs.find((i) => i.dictLabel === 'Recent')
      const resp = await getHistoryGameListApi(recentTab.form)
      recentTab.arr = resp.rows
      recentTab.total = resp.total
    },
    async getCareGameList() {
      const recentTab = this.tabs.find((i) => i.dictLabel === 'Favoritos')
      const resp = await getCareGameListApi(recentTab.form)
      recentTab.arr = resp.rows
      recentTab.total = resp.total
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.van-overlay) {
  width: 100%;
}
.game {
  position: relative;
  background-color: black;
  background-image: url('~@/assets/images/home/bg03.png');
  background-size: 100%;
  padding: 0 0.2rem;
  padding-top: 0.2rem;
  .swiper {
    width: 100%;
    img {
      width: 100%;
      border-radius: 0.2rem;
    }
  }

  .notice_css{
    width:80%;
    p{
      img{
        width:100%
      }
    }

  }
  .message {
    display: flex;
    height: 20px;
    margin: 0.1rem 0;
    img {
      width: 0.2rem;
      height: 0.2rem;
      margin: 0 0.1rem;
    }
    .content {
      flex: 1;
    }
    img:last-child {
      width: 0.3rem;
      height: 0.3rem;
    }
  }
  .card {
    ::v-deep .van-tabs__wrap {
      position: relative;
      //z-index: 9998;
      z-index: 999;
    }
    .tab-item {
      margin-top: 3px;
      img {
        width: 0.3rem;
        height: 0.3rem;
        &:last-child {
          width: 0.2rem;
          height: 0.35rem;
        }
      }
    }
    .item {
      padding: 0.15rem 0;
      .title img {
        width: 0.3rem;
        margin-right: 0.1rem;
      }
      .total {
        height: 80px;
        background-image: url('~@/assets/images/tabs/total-bg.jpg');
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 18px;
        color: #fdf468;
        font-size: 30px;
        font-weight: bold;
      }
      .games {
        display: grid;
        gap: 0.15rem;
        margin-top: 0.1rem;
        &.circle {
          grid-template-columns: repeat(4, 1fr);
          img {
            aspect-ratio: 1/1;
            border-radius: 30px;
          }
        }
        &.not-circle {
          grid-template-columns: repeat(3, 1fr);
          img {
            height: 2.192rem;
            border-radius: 10px;
          }
        }
        .games-item {
          position: relative;
          img {
            width: 100%;
            height: 100%;
          }
          .star {
            position: absolute;
            right: 0.1rem;
            top: 0.1rem;
            img {
              width: 0.2424rem;
              height: 0.2424rem;
            }
          }
        }
      }
      .more {
        cursor: pointer;
        margin-top: 0.2rem;
        font-size: 0.135rem;
        text-align: center;
        .info {
          color: #777777;
        }
      }
    }
  }

  .scroll-text-container {
    width: 100%;
    overflow: hidden;
  }

  .scroll-text {
    white-space: nowrap;
    animation-name: scroll;
    animation-timing-function: linear;
  }
  .div_contentTitle{
    margin: 5px 0;
    white-space: nowrap;
    overflow: hidden;
    color: #FFF;
    text-overflow: ellipsis;
  }
  .active_cTitle{
    //border: 1px solid;
    background: #e2e2e230;
    border-radius: 5px;
  }
  .GG_tc{
    background:#000;width: 375px;height: 50%;padding: 0 5px;border-radius:10px;
  }
  .icon_fj{
    position: fixed;
    bottom: 70px;
    width: 83px;
  }
  .icon_fj2{
    position: fixed;
    bottom: 170px;
    width: 83px;
  }
  .icon_fj3{
    position: fixed;
    bottom: 250px;
    width: 83px;
  }
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  ::v-deep(.van-tabs__wrap) {
    position: sticky !important;
    top: 50px !important;
    height: 55px !important;
    margin: 0 -0.2rem !important;
    border-bottom: 1px solid #3e3e3e;
  }
::v-deep(.noticeContent) {
  p {
    img {
      width:100%
    }
  }
}
  ::v-deep(.lunboCss){
    p{
      img{
        width: 100%;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  ::v-deep(.van-sticky--fixed) {
    overflow: hidden;
    width: 435px;
    left: calc((100vw - 439px) / 2);
  }
  .GG_tc {
    width: 375px;

  }
  .icon_fj{
    position: fixed;
    bottom: 70px;
    width: 83px;
    right: 165px;
    z-index: 999;
      right: calc(-210px + 50vw) !important;
  }
  .icon_fj2{
    position: fixed;
    bottom: 70px;
    width: 83px;
    right: 165px;
    z-index: 999;
      right: calc(-210px + 50vw) !important;
  }
  .icon_fj3{
    position: fixed;
    bottom: 70px;
    width: 83px;
    right: 165px;
    z-index: 999;
      right: calc(-210px + 50vw) !important;
  }
}

@media screen and (min-width: 768px) {
    ::v-deep(.van-popup--center) {
        width: 405px !important;
    }
}
::v-deep(.van-popup--center) {
    width: 90%;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
}
::v-deep(.van-popup__close-icon) {
    color: #000000 !important;
    font-size: 30px !important;
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid #000;
}

</style>
