<template>
  <div class="login">
    <van-tabs
      v-model="active"
      :ellipsis="false"
      style="overflow: hidden;"
      animated
    >
      <van-tab>
        <template #title>
          <div class="tab-title">
            <img
              src="@/assets/images/home/user.png"
              class="tab-icon"
              v-if="active == 0"
            />
            <img
              src="@/assets/images/home/user-p.png"
              class="tab-icon"
              v-else
            />
            <span :style="{ color: active == 0 ? '#f0b400' : '#777' }"
              >Login</span
            >
          </div>
        </template>
        <div class="edit-body">
          <a-input
            v-model="form.userName"
            placeholder="Nome de Usuário"
            allow-clear
            style="margin-top: 0.3rem;"
          >
            <template #prefix>
              <van-icon name="contact" color="#777" />
              <span style="color: var(--error);">*</span>
            </template>
          </a-input>
<!--          <a-input-->
<!--            v-model="form.password"-->
<!--            placeholder="Senha"-->
<!--            allow-clear-->
<!--            type="password"-->
<!--            style="margin-top: 0.3rem;"-->
<!--          >-->
<!--            <template #prefix>-->
<!--              <img src="@/assets/images/home/lock.png" />-->
<!--              <span style="color: var(&#45;&#45;error);">*</span>-->
<!--            </template>-->
<!--          </a-input>-->
          <div class="edit-item" style="margin-top: 0.25rem;">
            <div class="icon">
              <img src="@/assets/images/home/13C.png" alt="" />
              <span>*</span>
            </div>
            <input
                v-model="form.password"
                :type="!showeye?'password':'text  '"
                placeholder="Senha"
            />
            <div class="icon1" v-show="!showeye" @click="showeye=true">
              <img style="width: 0.24rem;" src="@/assets/images/home/eyehide.png" alt="" />
            </div>
            <div class="icon1" v-show="showeye" @click="showeye = false">
              <img style="width: 0.24rem;" src="@/assets/images/home/eyeshow.png" alt="" />
            </div>
          </div>
          <div v-if="showYzm" class="edit-item" style="display: flex;">
            <input
              v-model="form.code"
              type="text"
              placeholder="Digite o Número do Celular"
              style="padding-left: 0;"
              max-length="4"
            />
            <img :src="curImg" @click="getYzm()" />
          </div>
        </div>
      </van-tab>
      <van-tab>
        <template #title>
          <div class="tab-title">
            <img
              src="@/assets/images/home/phone.png"
              class="tab-icon"
              v-if="active == 1"
            />
            <img
              src="@/assets/images/home/phone-p.png"
              class="tab-icon"
              v-else
            />
            <span :style="{ color: active == 1 ? '#f0b400' : '#777' }"
              >Celular login</span
            >
          </div>
        </template>
        <div class="edit-body">
          <div style="font-size: small; text-align: center; color: #777;">
            Esse campo é apenas para cadastro ou login, não para vinculações!
          </div>
          <div class="edit-item2" style="margin-top: 0.05rem;">
            <div class="icon">
              <div class="left">
                <img class="flag" src="@/assets/images/home/BRL.png" alt="" />
                <span class="num">+55</span>
                <img class="down" src="@/assets/images/home/down.png" alt="" />
              </div>
              <div class="right">
                *
              </div>
            </div>
            <input
              type="text"
              name=""
              id=""
              placeholder="Digite o Número do Celular"
            />
          </div>
          <a-input
            v-model="yzm"
            placeholder="Digite o Número do Celular"
            :max-length="4"
            style="margin-top: 0.2rem;"
          >
            <template #prefix>
              <img src="@/assets/images/home/key.png" style="width: 0.2rem;" />
              <span style="color: var(--error);">*</span>
            </template>
            <template #suffix>
              <span style="color: #777;">Receber Código</span>
            </template>
          </a-input>
        </div>
      </van-tab>
      <van-tab>
        <template #title>
          <div class="tab-title">
            <img
              src="@/assets/images/home/emil.png"
              class="tab-icon"
              v-if="active == 2"
            />
            <img
              src="@/assets/images/home/emil-p.png"
              class="tab-icon"
              v-else
            />
            <span :style="{ color: active == 2 ? '#f0b400' : '#777' }"
              >E-mail login</span
            >
          </div>
        </template>
        <div class="edit-body">
          <div style="font-size: small; text-align: center; color: #777;">
            Esse campo é apenas para cadastro ou login, não para vinculações!
          </div>
          <a-input
            v-model="yzm"
            placeholder="Digite o Número do Celular"
            :max-length="4"
            style="margin-top: 0.2rem;"
          >
            <template #prefix>
              <img
                src="@/assets/images/home/emil-p.png"
                style="width: 0.2rem;"
              />
              <span style="color: var(--error);">*</span>
            </template>
          </a-input>
          <a-input
            v-model="yzm"
            placeholder="Digite o Número do Celular"
            :max-length="4"
            style="margin-top: 0.2rem;"
          >
            <template #prefix>
              <img src="@/assets/images/home/key.png" style="width: 0.2rem;" />
              <span style="color: var(--error);">*</span>
            </template>
            <template #suffix>
              <span style="color: #777;">Receber Código</span>
            </template>
          </a-input>
        </div>
      </van-tab>
    </van-tabs>
    <div class="footer">
      <div class="cheak">
        <van-checkbox
          v-model="checked"
          shape="square"
          icon-size="0.2rem"
          checked-color="#41bd0f"
        >
          <span style="color: f1f1f1; font-size: x-small;">
            Tenho 18 anos, li e concordo com
          </span>
          <span style="color: #f7b500; font-size: x-small;"
            >《Contrato do usuário》</span
          >
        </van-checkbox>
      </div>
      <button @click="handleLogin()">Login</button>
      <div class="links center">
<!--        <span @click="toClient">Atendimento ao cliente</span>-->
        <span>Jogar Grátis</span>
        <span @click="$emit('show:register')">Registrar agora</span>
      </div>
      <div class="info center">Outros métodos de início de sessão</div>
      <div class="softwares center">
        <img src="@/assets/images/home/Google.png" />
        <img src="@/assets/images/home/Facebook.png" />
        <img src="@/assets/images/home/Line.png" />
      </div>
    </div>
    <img
      class="close"
      src="@/assets/images/home/close.png"
      @click="$emit('close:login')"
    />
      <van-popup v-model="showPopup" closeable style="border-radius: 10px">
          <iframe style="min-width: 350px;min-height: 700px" :src="url" frameborder="0"></iframe>
      </van-popup>
  </div>
</template>

<script>
import { getYzmApi } from '../api'

export default {
  // async created() {
  //   await this.getYzm()
  // },
    mounted() {
        this.getYzm()
    },
    data() {
    return {
      checked: true,
      active: 0,
      yzm: undefined,
      showeye:false,
      form: {
        userName: undefined,
        password: undefined,
        code: undefined,
        uuid: undefined,
      },
      showYzm: false,
      curImg: '',
      showPopup:false,
      url:'https://direct.lc.chat/18333708/',
    }
  },
  methods: {
    tabChanged(e) {},
      toClient(){
          this.showPopup = true
      },
    async getYzm() {
      const { data: data } = await getYzmApi()
      this.showYzm = data.captchaEnabled
      if (data.captchaEnabled) {
        this.curImg = `data:image/png;base64,${data.img}`
        this.form.uuid = data.uuid
      }
    },
    async handleLogin() {
      // if (await this.$store.dispatch('login', this.form)) {
      //   this.$emit('close:login')
      // }
      try {
        if (await this.$store.dispatch('login', this.form)) {
          this.$emit('close:login');
        }
      } catch (error) {
        console.error('登录失败:', error);
  }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep(.van-tabs__wrap) {
  top: 0 !important;
}

::v-deep(.ant-input) {
  padding-left: 0.45rem !important;
}
.login {
  position: relative;
  background-color: #1c1c1c;
  border-radius: 10px;
  .tab-title {
    img {
      width: 0.2rem;
      height: 0.2rem;
      margin-right: 0.1rem;
    }
  }

  .edit-body {
    //max-height: 3.6364rem;
    overflow-y: scroll;
    padding-bottom: 0.1rem;
    padding: 0 0.1rem;

    .edit-item {
      position: relative;
      margin-top: 0.1rem;

      .icon {
        position: absolute;
        left: 0.06rem;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        margin-top: 0.04rem;

        img {
          width: 0.24rem;
        }

        span {
          color: #f50;
          font-size: 0.15rem;
          margin-left: 0.05rem;
          padding-top: 0.1rem;
        }
      }

      input {
        width: 100%;
        height: 0.4rem;
        padding-left: 0.5rem;
        border: none;
        border: 1px solid #777;
        border-radius: 10px;
        background: none;
        color: #fff;
        font-size: 0.15rem;
        margin-top: 0.1rem;

        &:placeholder-shown {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:focus {
          border: #f7b500 1px solid;
        }
      }
    }

    .edit-item2 {
      position: relative;
      margin-top: 0.1rem;

      .icon {
        position: absolute;
        left: 0.06rem;
        top: 50%;
        transform: translateY(-50%);
        margin-top: 0.04rem;
        display: flex;

        .left {
          display: flex;
          align-items: center;

          .flag {
            width: 0.18rem;
          }

          .num {
            color: #777;
            font-size: 0.15rem;
            padding-left: 0.1rem;
          }

          .down {
            width: 0.18rem;
            margin: 0 0.08rem 0 0.2rem;
          }
        }

        .right {
          border-left: 1px solid #777;
          padding-left: 0.08rem;
          color: #f50;
        }
      }

      input {
        width: 100%;
        height: 0.4rem;
        padding-left: 1.3rem;
        border: none;
        border: 1px solid #777;
        border-radius: 0.06rem;
        background: none;
        color: #fff;
        font-size: 0.15rem;
        margin-top: 0.1rem;

        &:placeholder-shown {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        &:focus {
          border: #f7b500 1px solid;
        }
      }
    }

    .city {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 0.3rem;
      border: 1px solid #777;
      padding: 0.08rem;
      border-radius: 0.08rem;

      div {
        display: flex;
        align-items: center;

        img {
          width: 0.18rem;
        }

        span {
          color: #f1f1f1;
          font-size: 0.15rem;
          padding-left: 0.1rem;
        }
      }
    }

    .tips {
      width: 100%;
      color: #777;
      font-size: 0.126rem;
      margin-top: 0.05rem;
    }

    .forca {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 0.25rem;

      span {
        display: block;
      }

      .tip {
        color: #f1f1f1;
        font-size: 0.17rem;
      }

      .light {
        width: 0.52rem;
        margin-left: 0.06rem;
        height: 0.078rem;
        border-radius: 0.2rem;
        background: #505050;
      }
    }
  }

  .footer {
    padding: 0.2rem;
    button {
      width: 100%;
      color: black;
      background-color: var(--yellow);
      padding: 0.1rem 0;
      margin: 0.1rem 0;
    }
    .links {
      gap: 20px;
      span {
        cursor: pointer;
        color: var(--yellow);
        font-size: small;
      }
    }
    .info {
      color: #777;
      margin-top: 0.2rem;
      font-size: small;
      text-align: center;

      &::before {
        content: '';
        display: inline-block;
        width: 35px;
        height: 1px;
        margin-right: 0.1rem;
        background: #777;
      }

      &::after {
        content: '';
        display: inline-block;
        width: 35px;
        height: 1px;
        margin-left: 0.1rem;
        background: #777;
      }
    }

    .softwares {
      margin-top: 0.1rem;
      gap: 0.1818rem;
      img {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }

  .icon1{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0.04rem;
    display: flex;
    right: 0.06rem !important;
  }

  .close {
    position: absolute;
    left: 50%;
    bottom: -50px;
    transform: translate(-50%);
  }
}
</style>
