<template>
  <div style="overflow: auto;">
    <van-tabs  v-model="active" animated @change="tabChanged" sticky offset-top="50" :style="tabStyle">
      <van-tab class="weiweiwei" v-for="(tab, index) in tabs" :title="tab" :key="index">
      </van-tab>
    </van-tabs>
    <router-view></router-view>
  </div>
</template>

<script>
import {EventBus} from "@/utils/eventBus";

export default {
  data() {
    return {
      active: 0,
      tabs: [
        'Eventos',
        // 'Missao',
        'Vip',
        'Rebate',
        'Pendente',
        // 'Juros',
        'Historico',
        // 'Fundo de Previdência',
      ],
      tabStyle: {
        '--lineWidth': this.getTextWidth('Eventos', '16px') + 'px',
      },
    }
  },
  methods: {
    getTextWidth(text, font) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = font || '16px Arial'
      // 测量文本宽度
      const metrics = context.measureText(text)
      return metrics.width + 10
    },
    tabChanged(e) {
        if(!this.$store.state.isLogin){
            if(e>1){
                return EventBus.$emit('show-login')
            }else{
                this.$router.push({ name: this.tabs[e] })
            }
        }else {
            this.$router.push({ name: this.tabs[e] })
        }
      this.tabStyle = {
        '--lineWidth': this.getTextWidth(this.tabs[e], '16px') + 'px',
      }
    },
  },
  mounted() {
    let tabItem = this.$route.name
    this.active = this.tabs.indexOf(tabItem)
  },
  watch: {
    $route(to, from) {
      let tabItem = to.name
      this.active = this.tabs.indexOf(tabItem)
    },
  },
}
</script>

<style scoped lang="scss">
// ::v-deep(.van-tabs){
//   position: fixed;
//   top: 0;
//   touch-action: manipulation;
// }
.container {
  //   width: 100vw;
  background: #303030;
}
@media screen and (min-width: 751px){
 ::v-deep(.van-tabs__nav--line){
   width: 435px;
   left: 50%;
   transform: translateX(-50%);
 }
}
</style>
