import request from '@/utils/request'

// 用户相关
export function getYzmApi() {
  return request({
    method: 'get',
    url: '/captchaImage',
  })
}

export function registerApi(data) {
  return request({
    method: 'post',
    url: '/pg/user/register',
    data,
  })
}

export function loginApi(data) {
  return request({
    method: 'post',
    url: '/pg/user/login',
    data,
  })
}

export function getUserInfoApi() {
  return request({
    method: 'get',
    url: '/pg/user/getInfo',
  })
}

export function editUserInfoApi(data) {
  return request({
    method: 'post',
    url: '/pg/user/editInfo',
    data,
  })
}

// 首页
export function getGameTypeApi() {
  return request({
    method: 'get',
    url: '/pg/dict/getPlatType',
  })
}

export function getGameListApi(params) {
  return request({
    method: 'get',
    url: '/pg/game/getGameList',
    params,
  })
}

export function getGameListApi1(gametype,plattype,pageNum,pageSize) {
  return request({
    method: 'get',
    url: '/pg/game/getGameList',
    params:{gametype:gametype,plattype:plattype,pageNum:pageNum,pageSize:pageSize},
  })
}

export function visitGameApi(gameCode) {
  return request({
    method: 'post',
    url: `/pg/game/intoGame/${gameCode}`,
  })
}

export function careGameApi(gameCode) {
  return request({
    method: 'post',
    url: `/pg/game/collectGame/${gameCode}`,
  })
}

export function getHistoryGameListApi(params) {
  return request({
    method: 'get',
    url: '/pg/game/getUserGameHistory',
    params,
  })
}

export function getCareGameListApi(params) {
  return request({
    method: 'get',
    url: '/pg/game/getUserGameCollect',
    params,
  })
}

// 获取充值档位
export function getDepositInfoApi() {
  return request({
    method: 'get',
    url: '/pg/game/getRechargeSetting',
  })
}
//充值
export function depositApi(data) {
  return request({
    method: 'post',
    url: '/pg/user/recharge',
    data,
  })
}

//获取用户充值订单
export function getDepositListApi(params) {
  return request({
    method: 'get',
    url: '/pg/user/getRechargeOrder',
    params,
  })
}

// 提现
export function withdrawApi(data) {
  return request({
    method: 'post',
    url: '/pg/user/withdraw',
    data,
  })
}

//获取用户提现订单
export function getSummaryListApi(params) {
  return request({
    method: 'get',
    url: '/pg/user/getWithdrawOrder',
    params,
  })
}

//绑定银行卡信息
export function addCardApi(data) {
  return request({
    method: 'post',
    url: '/pg/user/bankInfoAOU',
    data,
  })
}
//获取用户银行卡信息
export function getCardListApi(params) {
  return request({
    method: 'get',
    url: '/pg/user/getBankInfo',
    params,
  })
}

//获取用户银行卡信息（第一个）
export function getCardDetailApi(params) {
  return request({
    method: 'get',
    url: '/pg/user/getBankDetail/'+params,
  })
}

// 领取首充奖励
export function firstReward(id) {
  return request({
    method: 'get',
    url: '/pg/activityApi/receive/first/deposit/reward/' + id,
  })
}
// 领取宝箱奖励
export function boxReward(id) {
  return request({
    method: 'get',
    url: '/pg/activityApi/receive/box/reward/' + id,
  })
}
//领取累计充值活动奖励
export function receiveAccumulatedRechargeReward(id){
  return request({
    method: 'get',
    url: '/pg/activityApi/receive/accumulated/recharge/reward/' + id,
  })
}
//领取累计投注奖励
export function receiveAccumulatedBetsReward(id){
  return request({
    method: 'get',
    url: '/pg/activityApi/receive/accumulated/bets/reward/' + id,
  })
}

//领取累计签到奖励
export function receiveAccumulatedCheckinReward(id){
    return request({
        method: 'get',
        url: '/pg/activityApi/receive/accumulated/checkin/rewards/' + id,
    })
}

//点击签到
export function checkin(){
    return request({
        method: 'get',
        url: '/pg/activityApi/checkin',
    })
}
//获取签到状态
export function getCheckinStatus(){
    return request({
        method: 'get',
        url: '/pg/activityApi/getCheckinStatus',
    })
}
//判断是否是历史第一次充值
export function getQualificationsJudge() {
  return request({
    method: 'get',
    url: '/pg/activityApi/qualifications/activity',
  })
}

//存储参与活动信息
export function saveActivityInfo(data) {
  return request({
    method: 'post',
    url: '/pg/activityApi/saveActivityInfo',
    data,
  })
}
//领取历史首次充值奖励
export function firstRewardHistory(id) {
  return request({
    method: 'get',
    url: '/pg/activityApi/receive/history/first/deposit/reward/' + id,
  })
}
// 查询活动列表
export function activityList(params) {
  return request({
    method: 'get',
    url: '/pg/activityApi/list',
    params,
  })
}
// 根据活动id获取奖励信息
export function getMyReward(activityId) {
  return request({
    method: 'get',
    url: '/pg/activityApi/getMyReward/' + activityId,
  })
}
// 查询活动详情
export function activityDetail(id) {
  return request({
    method: 'get',
    url: '/pg/activityApi/detail/' + id,
  })
}
//获取转盘活动信息
export function getRotaryInfo() {
    return request({
        method: 'get',
        url: '/pg/activityApi/getRotaryInfo',
    })
}
//获取转盘获奖信息
export function getRotaryAware(rotaryType) {
    return request({
        method: 'get',
        url: '/pg/activityApi/getRotaryAware/' + rotaryType,
    })
}

export function getRotaryReward(rotaryType,reward) {
    return request({
        method: 'get',
        url: '/pg/activityApi/getRotaryReward',
        params:{rotaryType:rotaryType,reward:reward}
    })
}



// 公告
export function getByType(type) {
  return request({
    method: 'get',
    url: '/pg/announcement/getByType/' + type,
  })
}

//获取跳转路径
export function getHref(label) {
    return request({
        method: 'get',
        url: '/pg/announcement/getHref/' + label,
    })
}
//查询救援基金详情
export  function getResumeDetail(id){
  return request({
    method: 'get',
    url: '/pg/activityApi/getMyRmesueReward/' + id,
  })
}

//查询每日累计充值奖励
export  function getRechargeDetail(id){
    return request({
        method: 'get',
        url: '/pg/activityApi/getMyRechargeReward/' + id,
    })
}
//领取救援基金
export  function receiveResume(id){
  return request({
    method: 'post',
    url: '/pg/activityApi/receiveResumeReward/' + id,
  })
}
//领取每日充值奖励
export  function receiveRechargeReward(id){
  return request({
    method: 'post',
    url: '/pg/activityApi/receiveRechargeReward/' + id,
  })
}
// 用户流水
export function getUserBill() {
  return request({
    method: 'get',
    url: '/pg/user/getUserBill',
  })
}

// vip
export function getUserLevelList() {
  return request({
    method: 'get',
    url: '/pg/game/getUserLevelList',
  })
}

//代理 /pg/user/getAgentInfo
export function getAgentInfo() {
  return request({
    method: 'get',
    url: '/pg/user/getAgentInfo',
  })
}

//设置支付密码
export function setPayPassword(password,cofirmPassword) {
  return request({
    method: 'post',
    url: '/pg/user/setPayPassword',
    data:{password:password,cofirmPassword:cofirmPassword}
  })
}

//获取代理统计
export function getAgentMemberInfo(num) {
  return request({
    method: 'get',
    url: '/pg/user/getAgentMemberInfo/'+num,
  })
}

//获取代理成员信息
export function getAgentMemberInfoDeatail(pageNum,pageSize) {
  return request({
    method: 'get',
    url: '/pg/user/getAgentMemberInfoDeatail',
    params:{pageNum:pageNum,pageSize:pageSize}
  })
}

//兑换码
export function exchangeCode(code) {
  return request({
    method: 'get',
    url: '/pg/activityApi/exchangeCode/'+code,
  })
}


//充值奖励
export function getSameIpUsers(ip) {
  return request({
    method: 'get',
    url: '/pg/user/getSameIpUsers/'+ip,
  })
}



//所有历史明细
export function getUserActivityReward(params) {
  return request({
    method: 'get',
    url: '/pg/activityApi/getUserActivityReward',
    params:params
  })
}


