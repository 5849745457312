<template>
  <div class="gerenciar_conta">
    <div class="container">
      <div class="top between">
        <div>
          <span>Conta de Retirada</span>
          <span style="color: rgb(119, 119, 119);"
            >({{ itemsData.length }}/10)</span
          >
        </div>
        <div class="center" @click="isOpen = !isOpen" style="cursor: pointer;">
          <img
            class="small-button"
            :src="
              require(`@/assets/images/other/eye_${
                isOpen ? 'open' : 'hide'
              }.png`)
            "
            style="margin-right: 0.1rem;"
          />
          <span style="color: #feb705;">
            {{ isOpen ? 'Mostar' : 'Ocultar' }}</span
          >
        </div>
      </div>
      <div class="items">
        <div
          v-for="(item, index) in itemsData"
          :key="index"
          class="item"
          :class="{ selected: active === index }"
          @click="handleClick(index, item.id)"
        >
          <img
            src="@/assets/images/other/icon_normal_pix.png"
            style="margin-right: 0.1rem;"
          />
          <div>
            <div>
              <span style="color: #fff;">PIX</span>
              <span style="color: #777;">({{ item.accountType }})</span>
            </div>
            <div style="color: #777;" v-if="item.accountType=='CPF'">{{ item.cpfAccount }}</div>
            <div style="color: #777;" v-if="item.accountType=='PHONE'">{{ item.mobile }}</div>
            <div style="color: #777;" v-if="item.accountType=='EMAIL'">{{ item.email }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom" style="min-height: 150px;">
      <div class="row between" @click="showAddCard = true">
        <div class="center">
          <img
            src="@/assets/images/other/icon_normal_pix.png"
            style="margin-right: 0.1rem;"
          />
          <span>PIX</span>
        </div>
        <div class="center">
          <span style="color: #feb705;">Adicionar</span>
          <van-icon name="arrow" size="0.3rem" />
        </div>
      </div>
      <div class="row between" style="display: none" @click="showAddCard = true">
        <div class="center">
          <img
            src="@/assets/images/other/icon_szhb_xnb.png"
            style="margin-right: 0.1rem;"
          />
          <span>Moeda Digital</span>
        </div>
        <div class="center">
          <span style="color: #feb705;">Adicionar</span>
          <van-icon name="arrow" size="0.3rem" />
        </div>
      </div>
    </div>
    <a-modal
      v-model="showDialog"
      :closable="false"
      :footer="null"
      :maskClosable="false"
      :centered="true"
    >
      <div class="password_dialog">
        <div class="header">Inserir Senha</div>
        <div class="main">
          <div class="between">
            <span>Senha de Saque</span>
            <img
              class="small-button"
              :src="
                require(`@/assets/images/other/eye_${
                  hidePassword ? 'hide' : 'open'
                }.png`)
              "
              @click="hidePassword = !hidePassword"
            />
          </div>
          <div class="password">
            <van-password-input
              :value="password"
              :focused="showKeyboard"
              :mask="hidePassword"
              @focus="showKeyboard = true"
            />
            <van-number-keyboard
              v-model="password"
              :show="showKeyboard"
              @blur="showKeyboard = false"
            />
          </div>
          <div style="display: flex;">
            <span style="flex: 1;"
              >Para a segurança da sua conta, introduza a palavra-passe de
              levantamento</span
            >
            <span style="width: 0.8rem; color: #feb705; margin-left: 0.15rem;"
              >Esqueceu a Senha?</span
            >
          </div>
        </div>
        <button>Próximo</button>
        <img
          class="close"
          src="@/assets/images/home/close.png"
          @click="showDialog = false"
        />
      </div>
    </a-modal>
    <a-modal
      v-model="showAddCard"
      :closable="false"
      :footer="null"
      :maskClosable="false"
      :centered="true"
      wrapClassName="addCard-model"
    >
      <div class="header">
        <div style="font-size: large; color: #e3e3e3;">Inserir Senha</div>
        <van-icon name="cross" @click="showAddCard = false" />
      </div>
      <div class="main">
        <a-input v-model="form.name" allowClear placeholder="Insira o nome">
          <template #prefix>
            <img src="@/assets/images/my/id-card.png" style="width: 0.3rem;" />
          </template>
        </a-input>
        <a-select
          v-model="form.accountType"
          placeholder="Mês"
          style="width: 100%; margin-bottom: 0.2rem;"
        >
          <a-select-option v-for="i in options" :value="i">{{
            i
          }}</a-select-option>
        </a-select>
        <a-input
          v-if="form.accountType !== 'CPF'"
          v-model="otherValue"
          allowClear
          placeholder="Introduza a sua chave do PIX"
        >
          <template #prefix>
            <img src="@/assets/images/home/pix.png" />
          </template>
        </a-input>
        <a-input
          v-model="form.cpfAccount"
          allowClear
          placeholder="Introduza o número de 11 dígitos do CPF"
          :max-length="11"
        >
          <template #prefix>
            <img src="@/assets/images/home/emil-p.png" />
          </template>
        </a-input>
      </div>
      <div class="footer">
        <div style="font-size: small;">
          Verifique cuidadosamente o nome e o número do cartão, caso contrário,
          não poderá ser creditado
        </div>
      </div>
      <button @click="handleSubmit()">Confirmar</button>
    </a-modal>
  </div>
</template>

<script>
import { addCardApi, getCardListApi } from '../../api'
export default {
  data() {
    return {
      active: 0,
      isOpen: true,
      showDialog: false,
      hidePassword: true,
      password: '',
      hidePassword: true,
      showKeyboard: false,
      showAddCard: false,
      otherValue: undefined,
      form: {
        name: undefined,
        accountType: 'CPF',
        mobile: undefined,
        email: undefined,
        cpfAccount: undefined,
      },
      options: ['CPF', 'PHONE', 'EMAIL'],
      itemsData: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async handleSubmit() {
      if (this.form.accountType === 'PHONE') {
        this.form.mobile = this.otherValue
      } else if (this.form.accountType === 'EMAIL') {
        this.form.email = this.otherValue
      }
      const data = await addCardApi(this.form)
      if (data.code === 200) {
          this.showAddCard = false;
          this.form.name = undefined;
          this.form.email = undefined;
          this.form.mobile = undefined;
          this.otherValue = undefined;
          this.form.cpfAccount = undefined;
          this.getList();
        this.$toast.success(data.msg)
      } else {
        this.$toast.fail(data.msg)
      }
    },
    async getList() {
      const { data: data } = await getCardListApi()
      this.itemsData = data
    },
    handleClick(index, id) {
      this.active = index
      this.$emit('to-first-tab', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.gerenciar_conta {
  padding: 0.2rem;
  .container {
    padding: 0.2rem 0.1rem;
    background: #1c1c1c;
    border-radius: 10px;
    .items {
        margin-top: 0.1rem;
        max-height: 300px;
        overflow: hidden;
        overflow-y: scroll;
      .item {
        display: flex;
        align-items: center;
        padding: 0.1rem;
        border: 1px solid var(--grey-border);
        border-radius: 10px;
        margin-bottom: 0.1rem;
        img {
          width: 0.4rem;
          height: 0.4rem;
        }
      }
    }
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.1rem;
    padding-bottom: 0;
    background: #1c1c1c;
    .row {
      border: 1px solid rgb(80, 80, 80);
      border-radius: 10px;
      padding: 0.05rem;
      margin-bottom: 0.1rem;
      img {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
}

.addCard-model {
  .header {
    .van-icon {
      position: absolute;
      top: 0.1rem;
      right: 0.1rem;
    }
  }
  .main {
    margin-top: 0.1rem;
    .ant-input-affix-wrapper {
      margin-bottom: 0.2rem;
      img {
        width: 0.2rem;
      }
    }
  }
  button {
    background-color: var(--yellow);
    color: black;
  }
}

::v-deep(.ant-modal-content) {
  position: relative;
  background: none;
  .ant-modal-body {
    background: #1c1c1c;
    padding: 0.2rem;
    border: 1px solid var(--grey-border);
    border-radius: 10px;
    .header {
      text-align: center;
      font-size: larger;
    }
    button {
      width: 100%;
      margin-top: 0.3rem;
      background: #feb705;
      color: black;
      padding: 0.1rem 0;
    }
    .close {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -0.6061rem;
    }

    .password {
      margin: 0.2rem 0;
    }
  }
}

::v-deep(.van-password-input__item) {
  background: #1c1c1c;
  border-right: 1px solid #505050;
}

::v-deep(.van-password-input) {
  i {
    background: #fff;
  }
}
</style>
