<<template>
  <div class="footer">
    <div class="footer-content">
      <div class="nav">
        <div class="nav-item">
          <div class="header">Cssion</div>
          <div class="content">
            <div class="item">Bonus</div>
            <div class="item">Rebate</div>
            <div class="item">VIP</div>
            <div class="item">Convidar</div>
            <div class="item">Eventos</div>
            <div class="item">Missao</div>
          </div>
        </div>
        <div class="nav-item">
          <div class="header">Jogos</div>
          <div class="content">
            <div class="item">Cartas</div>
            <div class="item">Pescaria</div>
            <div class="item">Slots</div>
            <div class="item">Cassino Ao Vivo</div>
            <div class="item">Esporte</div>
            <div class="item">Loteria</div>
          </div>
        </div>
        <div class="nav-item">
          <div class="header">Suporte</div>
          <div class="content">
            <div class="item">Suporte</div>
            <div class="item">Bonus de Sugest</div>
            <div class="item">Central de Ajuda</div>
          </div>
        </div>
      </div>
      <div class="icons">
        <img src="@/assets/images/home/TG.png" />
        <img src="@/assets/images/home/FB.png" />
        <img src="@/assets/images/home/INS.png" />
        <img src="@/assets/images/home/FB.png" />
        <img src="@/assets/images/home/WS.png" />
        <img src="@/assets/images/home/black-fb.png" />
        <img src="@/assets/images/home/18+.png" />
      </div>
      <div class="introudce">
          BigWnr.com es el grupo eperativo de casinos en jnea más famoso delmundo,
        con cecenas de miles do
        juegos,alectrónica'mÃ¡quinastragarnedas'pesca'pbker/en
        viva,lateria'd:portes papulares! @ Grupo élicenciado e regulamentado
        pelo Gcverne de curacao e ooera ce acordocom a lcenca lAntilephon e)
        emitka para 444/.AZ, o grupo passou 0ortodas as auditorias regulatórias
        c cst¡ lcgalmentc autorizado a cperar tadosas locais de jagos! A rnelhor
        platafarma de entretenirnenlo partáti. da Intermet yiN444.COM
      </div>
      <div class="sponsor">
        <div class="first">
          <img
            v-for="(item, index) in 6"
            :src="require(`@/assets/images/footer/sp-0${index + 1}.png`)"
          />
        </div>
        <div class="second">
          <img
            v-for="item in [7, 8, 9]"
            :src="require(`@/assets/images/footer/sp-0${item}.png`)"
          />
        </div>
      </div>
      <div class="other">

        <span style="line-height: normal;"
          >©todos os direitos reservados 2002-2024</span
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.footer {
  background: black;
  .footer-content {
    padding: 0.1rem;
    text-align: center;

    .nav {
      display: flex;
      padding-bottom: 0.2rem;
      border-bottom: 1px solid #555555;
      .nav-item {
        flex: 1;
        text-align: start;
        .header {
          color: white;
          font-size: 0.2rem;
          padding-bottom: 0.1rem;
        }
        .content {
          .item {
            color: #777;
          }
        }
      }
    }

    .icons {
      padding: 0.2rem 0;
      img {
        width: 0.4rem;
        height: 0.4rem;
        margin-right: 0.1rem;
      }
    }

    .introudce {
      font-size: 0.12rem;
      color: #696863;
      padding: 0.2rem 0;
      border-bottom: 1px solid #555555;
    }
  }
}

.footer {
  .sponsor {
    .first {
      display: flex;
      justify-content: center;
      margin: 0.2667rem 0;
      img {
        width: 0.6424rem;
      }
    }
    .second {
      text-align: center;
      img {
        width: 0.6667rem;
      }
    }
  }
  .other {
    display: flex;
    align-items: center;
    height: 24px;
    margin-top: 0.2667rem;
    span {
      flex: 1;
      text-align: center;
      font-size: 0.1333rem;
      color: rgb(119, 119, 119);
      &:first-child {
        border-right: 1px solid rgb(80, 80, 80);
      }
    }
  }
}
</style>
