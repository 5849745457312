import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './styles/index.css'
import Vant from 'vant'
import 'vant/lib/index.css'
import CopyText from '@/components/icon/Copy.vue'
import VueCountUp from 'vue-countupjs' //数字递增插件
Vue.use(VueCountUp)
Vue.component('VueCountUp', VueCountUp) //全局注册组件
Vue.use(Vant)
Vue.use(Antd)
Vue.config.productionTip = false
Vue.component('copy', CopyText)

// 在Vue实例中注册一个全局过滤器
Vue.filter('formatNumber', function (value) {
    if (!value) return '';

    // 先将数字转换为字符串
    let [integerPart, decimalPart] = value.toString().split('.');

    // 使用正则表达式添加千位分隔符
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // 将小数部分和整数部分合并
    return decimalPart ? `${integerPart},${decimalPart}` : integerPart;
});


Vue.filter('formatDate', function(timestamp) {
  if (!timestamp) return '';

  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const day = ('0' + date.getDate()).slice(-2);
  const hours = ('0' + date.getHours()).slice(-2);
  const minutes = ('0' + date.getMinutes()).slice(-2);
  const seconds = ('0' + date.getSeconds()).slice(-2);

  return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
});

new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  },
  beforeCreate() {
    Vue.prototype.$bus = this //安装全局事件总线
  },
}).$mount('#app')
