<template>
  <img
    style="margin-left: 0.1rem; width: 0.2rem;"
    src="@/assets/images/my/复制.png"
    @click="copyToClipboard()"
  />
</template>

<script>
export default {
  props: ['text'],
  name: 'copyText',
  methods: {
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.text)
        this.$toast.success('A cópia foi bem sucedida')
      } catch (err) {
        console.error('Não é possível copiar o texto: ', err)
      }
    },
  },
}
</script>

<style></style>
