<template>
  <header>
    <van-icon
      name="arrow-left"
      size="0.3rem"
      class="left_btn"
      @click="$router.back()"
      style="cursor: pointer;"
    />
    <h2>{{ title }}</h2>
  </header>
</template>

<script>
export default {
  name: 'Navbar',
  props: {
    title: String,
  },
}
</script>

<style lang="scss" scoped>
header {
  position: relative;
  text-align: center;
  line-height: 50px;
  height: 50px;
  background-color: var(--dark-bg);
  .left_btn {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
  h2 {
    color: #e3e3e3;
  }
  span {
    display: inline-block;
    position: absolute;
    right: 10px;
    top: 2px;
    color: #feb705;
  }
}
</style>
