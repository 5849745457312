<template>
  <div class="slots">
    <header class="slots_header">
      <van-icon
          name="arrow-left"
          size="0.3rem"
          class="left_btn"
          @click="$router.back()"
          style="cursor: pointer;"
      />
      <h2 style="color:#fff;">Slots</h2>
      <span style="width: 25px;"></span>
    </header>
    <div>
      <van-search v-model="value" placeholder="Procurar jogos" />
    </div>
    <div class="p3">
      <!--title-->
      <div class="p3-1">
        <div class="p3-1-1" :data="index" v-for="(item,index) in gameTypeList" :key="index" @click="selLeftTitle(item,index)">
          <div  :class="{ 'title': true, 'title-active':index ==active  }">
            <img v-if="index == active" :src="item.selectedImg" alt="">
            <img v-else :src="item.img" alt="">
            <span>{{item.dictLabel}}</span>
          </div>
        </div>
      </div>
      <!--content-->
      <div class="p3-2">
        <div class="p3-2-1">
          <div :class="{ 'p3-2-1-1': true, 'p3-2-1-1-active': rightActive == 1 }" @click="selRightTopTitle(1)">Tudo</div>
          <div :class="{ 'p3-2-1-1': true, 'p3-2-1-1-active': rightActive == 2 }" @click="selRightTopTitle(2)">Popular</div>
          <div :class="{ 'p3-2-1-1': true, 'p3-2-1-1-active': rightActive == 3 }" @click="selRightTopTitle(3)">Recente</div>
          <div :class="{ 'p3-2-1-1': true, 'p3-2-1-1-active': rightActive == 4 }" @click="selRightTopTitle(4)">Favoritos</div>
        </div>
        <div class="p3-2-2">
          <div class="p3-2-2-1" v-for="(item,index) in gameList" :key="index">
            <div class="p3-2-2-1-1" style="" @click="getGameDetali(item,item.id)">
              <img class="p3-2-2-1-1-1" :src="item.imageurlRectangleEn" alt="">
              <div class="p3-2-2-1-1-2" style="">
                <span>{{item.gamenamePt}}</span>
                <span style="font-size: 12px">{{item.plattype}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="p3-2-3">
          <div @click="leftPage" style="cursor:pointer;"> < </div>
<!--          <img src="@/assets/back.png" alt="">-->
          <span>{{page}}</span>
<!--          <img src="@/assets/right.png" alt="">-->
          <div @click="rightPage" style="cursor:pointer;"> > </div>
        </div>
      </div>
    </div>
    <van-overlay :show="showWeiHu" class="showWeiHu" @click="showWeiHu = false">
        <div style="background: #00000095;border-radius: 10px;padding: 10px;display: flex;flex-direction: column;align-items: center;">
            <img src="../../assets/weihu.png" alt="">
            <h2>Em manutenção</h2>
        </div>
    </van-overlay>
  </div>
</template>

<script>
import { ref } from 'vue';
import {getGameListApi1, getGameTypeApi, getUserInfoApi, visitGameApi} from "@/api";
import { EventBus } from '@/utils/eventBus.js'
export default {
  data(){
    return{
      value:'',
      gameTypeList:'',
      gameList:'',
      active:0,
      rightActive:1,
      dictValue1:'1791012409033375746',
      page:'1',
      pageSize:'12',
        showWeiHu:false,
      dictLabel:'',
      getBalance:'',
        getIsStaff:this.$store.state.userInfo.isStaff,
    }
  },
  mounted() {
    let that = this
    if (that.$route.query.num ==null && that.$route.query.num <0){
      that.active = 0
    }else{
      that.active = that.$route.query.num
    }
    that.getGameParams();
    that.getGameType();
    that.getGameList();
    this.getMoney();
  },
  methods:{
      getGameParams(){
          if (this.active == 0){
              this.dictValue1 = '1791012409033375746';
              this.dictLabel = 'Poplular';
          }else if (this.active == 1){
              this.dictValue1 = '1791012721903288322';
              this.dictLabel = 'Slots';
          }else if (this.active == 2){
              this.dictValue1 = '1823289061352153089';
              this.dictLabel = 'pg';
          }else if (this.active == 3){
              this.dictValue1 = '1823290902504914946';
              this.dictLabel = 'pp';
          }else if (this.active == 4){
              this.dictValue1 = '1823291057799020545';
              this.dictLabel = 'JILI';
          }else if (this.active == 5){
              this.dictValue1 = '1823291169149403137';
              this.dictLabel = 'JDB';
          }else if (this.active == 6){
              this.dictValue1 = '1793326365366300673';
              this.dictLabel = 'Recent';
          }else if (this.active == 7){
              this.dictValue1 = '1793327402445553666';
              this.dictLabel = 'Favoritos';
          }
      },
    getGameType() {
      getGameTypeApi().then(res=>{
        this.gameTypeList = res.data
      })
    },
    getGameList(){
      getGameListApi1(this.dictValue1,this.dictLabel,this.page,this.pageSize).then(res=>{
        this.gameList = res.rows
      })
    },
    selLeftTitle(item , index){
      let that = this
      that.active = index
      that.dictValue1 = item.dictValue
      that.dictLabel = item.dictLabel
      that.page = 1
      that.getGameList();
      console.log(that.active,that.dictValue1,that.dictLabel)
    },
    selRightTopTitle(num){
      let that = this;
      that.page = 1;
      that.rightActive = num
      if (num ==2){
        that.active = 0;
        that.dictValue1='1791012409033375746';
        that.getGameList();
      }
    },
    leftPage(){
      let that = this
      if (that.page == 1){
        that.page =1;
        return
      }
      that.page--;
      console.log(that.page)
      that.getGameList()
    },
    rightPage(){
      let that = this
      that.page = parseInt(that.page)+1;
      console.log(that.page)
      that.getGameList()
    },
      getMoney(){
          getUserInfoApi().then(res=>{
              if (res.code==200){
                  this.getBalance = res.data.balance
                  this.getIsStaff = res.data.isStaff
              }
          })
      },
    async getGameDetali(item,code) {
        if (!this.$store.state.isLogin) {
            return EventBus.$emit('show-login')
        }
        console.log(item.plattype)
        // if (item.plattype=='pp'){
        //     this.showWeiHu=true
        //     return;
        // }
        // if(this.getIsStaff!=0){
        //     this.$toast.fail("Prezado(a) blogueiro(a), você não tem permissão para entrar no jogo.")
        //     return
        // }
        if (this.getBalance<3){
        // if (this.getBalance<3 && this.getIsStaff==0){
            this.$toast.fail("Seu saldo  menor que R$3, Por favor.faca um deposito para jogar")
            return;
        }

        const { data: data,msg: msg } = await visitGameApi(code)
      if (data) {
        this.$router.push({ path: '/home/game/detail', query: { url: data } })
      }
    },
  }
}

</script>

<style lang="scss" scoped>
.slots{
  padding-top: 10px;
  height: 100vh;
  background: var(--dark-bg);
  .p3{
    display: flex;
    .p3-1{
      width: 20%;
      .p3-1-1{
        .title{
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
          margin: 5px 0;
          font-size: 10px;
          img{
            width: 25px;
            height: 25px;
          }
        }
        .title-active{
          background: #feb705;
          border-radius: 10px;
          span{
            color: #000;
          }
        }
      }
    }
    .p3-2{
      width: 80%;
      .p3-2-1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0px 10px 10px 10px;
        .p3-2-1-1{
          font-size: 12px;
          height: 28px;
          border-radius: 10px;
          background: #343232;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 10px;
          cursor: pointer;
        }
        .p3-2-1-1-active{
          background: #feb705;
          color: #000;
        }
      }
      .p3-2-2{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        margin-left: 10px;
        .p3-2-2-1{
            margin:5px;
          .p3-2-2-1-1{
            //display: flex;
            //margin: 5px;
            //flex-direction: column;
            //justify-content: center;
            //align-items: center;
            //cursor: pointer;
              position:relative;
            .p3-2-2-1-1-1{
              width: 80px;
              border-radius: 10px
            }
            @media screen and (min-width: 435px) {
              .p3-2-2-1-1-1{
                width: 100px ;
                border-radius: 10px
              }
              .p3-2-2-1-1-2{
                /*margin-top: 60px !important;*/

              }
            }
            .p3-2-2-1-1-2{
              /*margin-top: 70px;*/
              /*position: fixed;*/
              //  margin-top: -35px;
              //display: flex;
              //flex-direction: column;
              //align-items: center;
              //  white-space: nowrap;
              //  text-overflow: ellipsis;
              //  overflow: hidden;
              //  word-break: break-all;
                position: absolute;
                bottom: -10px;
                width: 100%;
                display: flex;
                text-align: center;
                line-height: 1;
                flex-direction: column;
                padding-bottom: 8px;
              span{
                  display: block;
                  width: 80%;
                  text-align: center;
                  margin: 0px auto;
                  color: rgb(255, 255, 255);
                  font-size: 12px;
                  padding-bottom: 8px;
              }
            }
          }
        }
      }
      .p3-2-3{
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        left: 50%;
        bottom: 10px;
        div{
          font-size: 20px;
          font-weight: bold;
          width: 20px;
          height: 20px;
          background: #464444;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        span{
          margin: 0 20px;
          width: 20px;
          height: 20px;
          border-radius: 5px;
          display: flex;
          background: #464444;
          border: 1px solid #858585;
          color: #fff;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .slots_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  ::v-deep(.van-search){
    background: var(--dark-bg);
  }
  ::v-deep(.van-search__content){
    background-color: var(--dark-bg);
    border: 1px solid;
    border-radius: 20px;
  }
  ::v-deep(.van-field__control){
    color:#fff
  }
}

.showWeiHu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 100px;
    }
    h2{
        color: #fff;
    }
}
</style>
