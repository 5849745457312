<template>
  <div class="container">
    <!-- <div class="top between"> -->
    <!-- <span>
        <span>Total de Saques</span>
        <span style="color: white; margin-left: 0.1rem;">R$ 0,00</span>
      </span> -->
    <!-- </div> -->
    <van-list
      v-model="loading"
      :finished="finished"
      loading-text="Carregamento..."
      finished-text="Não sobrou mais nada."
      @load="getTableList()"
    >
      <div class="items">
        <div class="item" v-for="(item, index) in arr" :key="index">
          <div class="between">
            <div>
              <img
                src="@/assets/images/other/icon_normal_pix.png"
                class="small-button"
              />
              <span style="margin-left: 0.1rem;"
                >Emitindo paraPIX(****{{ item.bankAccount.slice(-4) }})</span
              >
            </div>
            <div>R$ {{ item.amount }}</div>
          </div>
          <div class="between">
            <div>
              <div style="margin-top: 5px"><span :id="index" style="color: #777;">{{ item.id }}</span> <img style="width: 0.2rem; height: 0.2rem;" src="@/assets/images/my/复制.png" @click="copyContent(index)" /></div>
              <span style="color: #777;">{{ item.updateTime }}</span>
            </div>
            <div>
              <span v-if="item.status ==1" style="font-size: 12px;color:#31be31;">Retirada bem-sucedida</span>
              <span v-if="item.status ==0 || item.status ==3" style="font-size: 12px;color:#ccc;">retirada em andamento</span>
              <span v-if="item.status ==2" style="font-size: 12px;color:red;">retirada falhada</span>
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getSummaryListApi } from '../../api'
export default {
  data() {
    return {
      sj: 'Hoje',
      arr: [],
      loading: false,
      finished: false,
      form: {
        pageNum: 0,
        pageSize: 10,
      },
    }
  },
  created() {
    this.getTableList()
  },
  methods: {
    async getTableList() {
      this.loading = true
      this.form.pageNum += 1
      console.log(this.form.pageNum)
      const resp = await getSummaryListApi(this.form)
      this.arr = [...this.arr, ...resp.rows]
      this.loading = false
      if (this.arr.length >= resp.total) {
        console.log('结束')
        this.finished = true
      }
    },
    copyContent(index) {
      const textToCopy = document.getElementById(index).innerText;
      const tempInput = document.createElement('input');
      tempInput.value = textToCopy;
      document.body.appendChild(tempInput);
      tempInput.select();
      document.execCommand('copy');
      document.body.removeChild(tempInput);
      this.$toast.success("Copiado com sucesso")
    },
  },
}
</script>

<style lang="scss" scoped>
.top {
  padding: 0.1rem;
}

.items {
  padding: 0.2rem 0.1rem;
  .item {
    padding: 0.2rem;
    border-radius: 10px;
  }
  .item:nth-child(odd) {
    background-color: #1c1c1c;
  }
  .item:nth-child(even) {
    background-color: #303030;
  }
}
</style>
