<template>
  <div class="container">
    <navbar title="Detalhes da auditoria"/>
    <div style="background: #303030">
      <div style="height: 10px"></div>
      <div class="detailCss">
        <div class="detailCss-1"><span class="detailCss-1-1">Tipo de Transação</span><span class="detailCss-1-2">Recarga de Membro</span></div>
        <div class="detailCss-1"><span class="detailCss-1-1">Nome do Evento</span><span style="max-width: 58%;text-align: right">Login diário na APP Obter prémios de luxo</span></div>
        <div class="detailCss-1"><span class="detailCss-1-1">Status da auditoria</span><span  style="color:#FFAA09;" class="detailCss-1-2">Em andamento</span></div>
        <div class="detailCss-1"><span class="detailCss-1-1">Valor da transação</span><span class="detailCss-1-2">R$ 0,89</span></div>
        <div class="detailCss-1"><span class="detailCss-1-1">Vezes auditado</span><span class="detailCss-1-2">1.00</span></div>
        <div class="detailCss-1"><span class="detailCss-1-1">Auditoria</span><span class="detailCss-1-2">R$ 0,89</span></div>
        <div class="detailCss-1"><span class="detailCss-1-1">Auditoria pendente</span><span class="detailCss-1-2">R$ 0,56</span></div>
        <div class="detailCss-1"><span class="detailCss-1-1">Auditado</span><span class="detailCss-1-2">R$ 0,33</span></div>
        <div class="detailCss-1"><span class="detailCss-1-1">Limitado à plataforma</span><span class="detailCss-1-2">-</span></div>
        <div class="detailCss-1"><span class="detailCss-1-1">Jogo restrito</span><span class="detailCss-1-2">-</span></div>
        <div class="detailCss-1"><span class="detailCss-1-1">Hora de Criação</span><span class="detailCss-1-2">24/05 20:22:12</span></div>
      </div>
      <div style="min-height: 400px"></div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: {Navbar},
  data() {
    return {

    }
  },

  methods: {

  },
}
</script>

<style lang="scss" scoped>

.detailCss{
  margin:0 10px;
  border-radius: 10px;
  background: #1c1c1c;
  padding: 10px;
  .detailCss-1{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    padding: 5px 0;
    border-bottom: 1px solid #454545;
    .detailCss-1-1{
      color: #5f6165;
    }
  }
  .detailCss-2{
    color:#fff;
  }
}
</style>
