<template>
  <header class="header">
    <div class="left center">
      <a-icon
        @click="handleClick"
        :type="showMenu ? 'menu-fold' : 'menu-unfold'"
        style="font-size: 0.3rem; color: var(--text-color);"
      />
<!--      <img-->
<!--        src="../../assets/images/home/logo-home.png"-->
<!--        class="logo"-->
<!--      />-->
<!--      <img-->
<!--        src="../../assets/images/home/logo-home1.png"-->
<!--        class="logo"-->
<!--      />-->
      <div class="logoCss" v-html="logoList"></div>
<!--      <div class="logoCss">-->
<!--        <p><img src="https://h1502.oss-cn-hongkong.aliyuncs.com/2024/05/31/10e6305f752e4d87b934fe5dec7f5dc6.png" alt="">-->
<!--        </p>-->
<!--      </div>-->
    </div>
    <div class="right center" style="margin-left: -35px">
      <div v-if="showBtns" class="btns">
        <van-button
          class="left-btn"
          size="mini"
          round
          @click="EventBus.$emit('show-login')"
        >
          <span style="color: black;">Login</span>
        </van-button>
        <van-button
          class="right-btn"
          size="mini"
          round
          @click="EventBus.$emit('show-Register')"
        >
          <span style="color: #feb705;">Register</span>
        </van-button>
      </div>
      <div style="display:flex;justify-content: center;align-items: center;border: 1px solid rgb(204 204 204 / 22%);border-radius: 20px;">
        <img v-if="!showBtns" style="width: 18px" src="@/assets/images/my/blance.png" />
        <span v-if="!showBtns">{{ $store.state.userInfo.balance | formatNumber}}</span>
        <!-- <VueCountUp v-if="!showBtns" :start-value="0" :end-value="$store.state.userInfo.balance" :duration="2" :decimalPlaces="2" :options="options"/> -->
        <img v-if="!showBtns"
             style="width:18px"
             :src="imageUrl"
             alt="旋转图片"
             @click="rotateImage"
             :style="{ transform: `rotate(${rotation}turn)` }"
        >
      </div>
      <div v-if="!showBtns" style="margin-left: 5px">
        <div class="toPage" style="display: flex;align-items: center">
          <span @click="toCZ" style="color: #000;padding-left: 3px">{{content}} </span>
          <img src="@/assets/d.png">
        </div>
      </div>
      <img class="search" src="@/assets/images/home/search.png" />
    </div>
    <!-- <div class="header-container">
      <div class="left">
        <div class="time">
          <span>04/25 04:53:03</span>
          <span>(UTC-03:00)</span>
        </div>
        <a-select
          default-value="lucy"
          style="width: 1.6rem; border-radius: 1.2rem; font-size: 0.2rem;"
        >
          <a-select-option value="jack">
            Português
          </a-select-option>
          <a-select-option value="lucy">
            English
          </a-select-option>
        </a-select>
      </div>
      <div class="right">

      </div>
    </div> -->
  </header>
</template>

<script>
import VueCountUp from 'vue-countupjs' //引入
import { EventBus } from '@/utils/eventBus.js'
import {getByType, getUserInfoApi} from "@/api";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      options:{
        useEasing: true, // 缓动动画 easing
        useGrouping: true, // 1,000,000 vs 1000000
        separator: ',', // 数字分隔符
        prefix: '', // 前缀
        suffix: '', // 后缀
        separator: ',', // 千位分隔符（如果需要）
        decimal: '.', // 小数点符号（如果需要）
      },
      showMenu: false,
      EventBus,
      content:'Depositar',
      option1 : [
        { text: 'Saque', value: 1 },
        { text: 'Juros', value: 2 },
      ],
      value1 : 0,
      rotation: 0,
      isRotating: false,
      imageUrl: require('@/assets/images/my/刷新.png'),
      logoList:'',
        timerId:null,
    }
  },
  computed: {
    ...mapState(['isLogin', 'userInfo']),
    computedBalance() {
      return this.userInfo.balance || 0; // 如果 userInfo 不存在或没有 balance，则返回 0
    },
    showBtns() {
      return !this.isLogin;
    },
  },
  created() {
    this.$bus.$on('updShowMenu', (res)=> {
      this.showMenu = res;
      this.$emit('show-menu', this.showMenu)
    })
  },
  mounted() {
    this.getUserInfoApi();
    this.getByType();
  },
    beforeDestroy() {
        if (this.timerId) {
            clearTimeout(this.timerId);
            this.timerId = null;
        }
    },
  methods: {
    getByType(){
      getByType(0).then(res=>{
        // this.logoList = res.data;
        if(res.data) {
          this.logoList = res.data[0].noticeContent
        }
      })
    },
    handleClick() {
      this.showMenu = !this.showMenu
      this.$emit('show-menu', this.showMenu)
    },
    toCZ(url){
      this.$router.push('/home/deposit')
    },
    getUserInfoApi(){
      let token = localStorage.getItem('token')
      if(!token){
        return
      }
      getUserInfoApi().then(res=>{
        // console.log(res);
        this.$store.commit('updateBalance', res)
        this.balance = res.data.balance
      })
    },
    rotateImage() {
      if (!this.isRotating) {
        this.isRotating = true;
        this.rotation += 2; // 旋转两圈
        this.timerId = setTimeout(() => {
          this.isRotating = false;
        }, 2000); // 旋转动画持续时间 2 秒
      }
      this.getUserInfoApi()
    },
  },
}
</script>

<style lang="sass" scoped>

.header
    position: relative
    z-index: 2
    top:0
    display: flex
    padding: 0 0.2rem
    align-items: center
    width: 100%
    height: 0.8rem
    background: #1c1c1c
    border-bottom: 0.01rem solid #505050
    .logo
            height: 87%
            padding-left: 0.1rem
            padding-right: 0.5rem
    .header-container
        display: flex
        justify-content: space-between
        align-items: center
        width: calc(50vw + 4.2rem)
        .left
            display: flex
            .time
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                font-size: 0.15rem
                margin-right: 0.2rem
        .right
            .left-btn
                width: 1rem
                background-color:#FEB705
                border:#FEB705
                margin-right: 0.3rem
                span
                    color:#000000
            .right-btn
                width: 1rem
                background-color: transparent
                border-color: #FEB705
                span
                    color: #FEB705





.ant-select-selection--single
    border-radius: 1.2rem !important
    background-color: #1c1c1c !important
    border-color: #505050 !important
    box-shadow: none

.ant-select-focused .ant-select-selection, .ant-select-selection:focus, .ant-select-selection:active
    box-shadow: none !important

.ant-select-selection:hover
    border-color:  #505050 !important
    .ant-select-selection-selected-value,path
        color:#FEB705 !important



.ant-select-open .ant-select-selection
    border: 1px solid #FEB705 !important
    box-shadow: none
</style>

<style lang="scss" scoped>
.header {
  position: sticky;
  top: 0;
  z-index: 999;
  height: 0.6061rem;
  display: flex;
  justify-content: space-between;
  .left {
    .logo {
      height: 30px !important;
    }
  }
  .right {
    .btns {
      display: flex;
      .left-btn {
        width: 0.6303rem;
        font-size: 0.1rem;
        background-color: #feb705;
        border-color: #feb705;
        margin-right: 0.05rem;
      }

      .right-btn {
        width: 0.6303rem;
        font-size: 0.1rem;
        background-color: transparent;
        border-color: #feb705;
      }
    }
  }
  .search {
    width: 0.1697rem;
    height: 0.1697rem;
    margin-left: 0.1rem;
  }
}
.toPage{
  background: #feb705;
  font-size: 10px;
  border-radius: 5px;
}
img {
  transition: transform 2s ease; /* 旋转动画持续时间和缓动函数 */
}
::v-deep(.logoCss){
  p{
    img{
      height: 30px !important;
      margin-top: 10px;
    }
  }
}
</style>
