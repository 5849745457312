<template>
  <div class="container">
    <div class="top between">
      <div class="center">
        <a-select
          ref="select"
          v-model="sj"
          size="small"
          :dropdownMatchSelectWidth="false"
          @focus="focus"
          @change="handleChange"
        >
          <a-select-option value="Tudos">Tudos</a-select-option>
          <a-select-option value="Não Começou">Não Começou</a-select-option>
          <a-select-option value="Em curso">Em curso</a-select-option>
          <a-select-option value="Completado">Completado</a-select-option>
        </a-select>
        <img
          class="small-button"
          style="margin-left: 0.1rem;"
          src="@/assets/images/my/客服.png"
        />
      </div>
      <span class="center">
        <span style="color: #777;">Total pendente de auditoria</span>
        <span style="color: white; margin-left: 0.1rem;">R$ 0,00</span>
        <img class="small-button" src="@/assets/images/my/刷新.png" />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sj: 'Tudos',
    }
  },
}
</script>

<style lang="scss" scoped>
.top {
  padding: 0.1rem;
}

::v-deep(.ant-select-selection) {
  border-radius: 10px;
  background: var(--dark-bg);
  border-color: #505050;
  &:hover {
    border: 1px solid #ffcb2e;
    box-shadow: none;
  }
}

::v-deep(.ant-select-selection-selected-value) {
  color: #777;
}
::v-deep(.ant-select-open .ant-select-selection) {
  border: 1px solid #ffcb2e;
  box-shadow: none;
}

::v-deep(.ant-select-focused .ant-select-selection) {
  border: 1px solid #ffcb2e;
  box-shadow: none;
}
</style>
